.is-checkradio[type='checkbox'] + label::after,
.is-checkradio[type='checkbox'] + label:after {
  border-color: var(--primary-color);
}

.is-checkradio[type='checkbox'] + label::before,
.is-checkradio[type='checkbox'] + label:before {
  border-radius: 0;
  border-color: var(--border-color);
}
.is-checkradio[type='checkbox'] + label::before,
.is-checkradio[type='checkbox'] + label:before,
.is-checkradio[type='darker'] + label::before,
.is-checkradio[type='darker'] + label:before {
  border-radius: 0;
  border-color: var(--checkbox-border-color);
}

.is-checkradio[type='checkbox']:hover:not([disabled]) + label::before,
.is-checkradio[type='checkbox']:hover:not([disabled]) + label:before,
.is-checkradio[type='radio']:hover:not([disabled]) + label::before,
.is-checkradio[type='radio']:hover:not([disabled]) + label:before {
  border-color: var(--primary-color) !important;
}
