.email-wrapper {
  opacity: 0;
  visibility: hidden;
  transition: 0.1s;
  &.visible {
    opacity: 1;
    visibility: visible;
  }
  .email {
    color: var(--font-color);
    .header {
      padding: 0 16px;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      align-items: center;
      border-bottom: 1px solid var(--contact-search-background-color);
      padding-bottom: 15px;
      .icons-wrapper {
        display: flex;
        justify-content: space-between;
        min-width: 100px;
        i {
          font-size: 17px;
          cursor: pointer;
          transition: 0.1s;
          &:hover {
            color: var(--primary-color);
          }
        }
      }
    }
    .content {
      border-bottom: 1px solid var(--contact-search-background-color);
      display: flex;
      align-items: center;
      flex-direction: column;
      input {
        padding: 20px 15px;
        box-shadow: none;
        border: none;
        background-color: transparent !important;
        border-radius: unset;
        border-bottom: 1px solid var(--contact-search-background-color);
      }
    }
    .submit-wrapper {
      padding: 0 16px;
      button {
        max-width: 100px;
      }
    }
  }
}

.full-screen {
  .notes-wrapper {
    .note {
      .content {
        textarea {
          height: 62vh;
        }
        .associated {
          .associated-search {
            left: 16px;
          }
        }
      }
    }
  }
}

.hidden {
  .notes-wrapper {
    .note {
      .header {
        .icons-wrapper {
          i:first-child {
            transition: 0.5s;
            transform: rotate(180deg);
          }
        }
      }
    }
  }
}

.background-default {
  .notes-wrapper .note {
    .content {
      textarea::placeholder {
        color: var(--gray);
      }
    }
  }
}
