html {
  overflow: visible;
}

body {
  font-family: var(--font-family);
  color: var(--font-color);
  --primary-color-darken-20: var(--green-darken-20);
}

h1 {
  font-family: var(--font-family-headers);
  font-weight: 500;
  color: var(--font-color);
}

h2 {
  font-family: var(--font-family-headers);
  font-weight: 500;
  color: var(--font-color);
}

h3 {
  color: var(--font-color);
  font-family: var(--font-family-headers);
  font-weight: 500;
}

h4 {
  color: var(--font-color);
  font-family: var(--font-family-headers);
  font-weight: 500;
}

h5 {
  color: var(--font-color);
  font-family: var(--font-family-headers);
  font-weight: 500;
}

h6 {
  font-family: var(--font-family-headers);
  color: var(--font-color);
}

p {
  font-family: var(--font-family);
  font-weight: 400;
}

// LAYOUT STYLES

p {
  &.title {
    font-weight: 500;
    color: var(--font-color);
  }

  &.error {
    font-size: var(--error-font-size);
  }

  &.small-title {
    text-transform: uppercase;
    color: var(--gray);
  }
  &.info {
    font-size: calc(var(--font-size) - 1px) !important;
    color: var(--gray);
  }
}

a {
  &:hover {
    color: var(--primary-color);
  }
}

label > p {
  margin-top: -3px;
}

.wrapper.background-custom {
  background-position-y: 80px;
  background-repeat: no-repeat;
  background-size: cover;

  .overlay {
    z-index: 0;
    background: rgba(0, 0, 0, 0.7);
  }
}

// custom section

.section.custom-section {
  padding: 50px 0;
  min-width: 1100px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.input:active,
.input:focus,
.is-active.input,
.is-active.textarea,
.is-focused.input,
.is-focused.textarea,
.select select.is-active,
.select select.is-focused,
.select select:active,
.select select:focus,
.textarea:active,
.textarea:focus {
  box-shadow: none !important;
}

.input.filtered {
  background-color: var(--secondary-btn-background-color) !important;
  border-color: transparent !important;
  border-top-right-radius: unset !important;
  border-bottom-right-radius: unset !important;
  border-right: 1px solid var(--light-gray) !important;

  &:hover {
    border-color: var(--primary-color) !important;
  }

  &.filtered-right {
    border-top-left-radius: unset !important;
    border-bottom-left-radius: unset !important;
    border-top-right-radius: 4px !important;
    border-bottom-right-radius: 4px !important;
  }
}

input[type='search']::-webkit-search-cancel-button {
  /* Remove default */
  -webkit-appearance: none;
  /*New styles */
  cursor: pointer;
  width: 11px;
  height: 11px;
  transition: 0.1s;
  clip-path: var(--close-x-icon-path);
  background: var(--light-gray-20);

  &:hover {
    background: var(--label-border-color);
  }
}

.is-checkradio.is-danger[type='checkbox']+label::before,
.is-checkradio.is-danger[type='checkbox']+label:before,
.is-checkradio.is-danger[type='radio'] + label::before,
.is-checkradio.is-danger[type='radio'] + label:before {
  border-color: var(--border-danger);
}

.is-checkradio.active[type='checkbox'] + label:before {
  border-color: var(--primary-color);
}

.is-checkradio[type='checkbox'] + label {
  border-radius: unset;
}

.search-wrapper,
.search {
  input {
    font-size: 15px !important;
    box-shadow: none !important;
    border: solid 1px transparent !important;

    &:not(.custom-select__input) {
      background-color: var(--search-background) !important;
    }
  }
}

input,
.input,
textarea,
.filtered,
.select select {
  font-size: 15px !important;
  transition: 0.1s;
  font-family: var(--font-family);
  color: var(--font-color) !important;
  border-color: var(--input-border-color) !important;
  background-color: var(--input-background-color) !important;
  outline: none;

  &::placeholder {
    color: var(--gray);
  }

  &:hover:not(.custom-select__input) {
    border-color: var(--primary-color) !important;
  }

  &:focus:not(.custom-select__input) {
    background-color: var(--placeholder-background-color) !important;
    border-color: var(--primary-color) !important;
  }

  &:disabled {
    opacity: 0.6;

    &:hover {
      border-color: var(--input-border-color) !important;
    }
  }

  &.is-danger {
    border-color: var(--border-danger) !important;
  }

  &:-webkit-autofill {
    box-shadow: 0 0 0 50px var(--autofill-color) inset !important;
    -webkit-box-shadow: 0 0 0 50px var(--autofill-color) inset !important;
    -webkit-text-fill-color: var(--font-color);
  }
}

.control.has-icons-left {
  font-size: 15px;

  .input {
    & ~ .icon {
      color: var(--gray) !important;
    }

    &:focus ~ .icon {
      color: var(--primary-color) !important;
    }
  }
}

.control {
  .required-field {
    display: flex;

    .astericks {
      color: var(--primary-color);
      margin-right: 5px;
    }
  }
}

.is-checkradio[type='radio'] + label::after,
.is-checkradio[type='radio'] + label:after {
  background-color: var(--primary-color);
}

.visually-hidden {
  margin: -1px;
  padding: 0;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0 0 0 0);
  clip: rect(0, 0, 0, 0);
  position: absolute;
}

.custom-select,
.custom-select-dashboard,
.custom-select-filter-right,
.custom-select-filter-left,
.custom-select-filter {
  font-size: var(--font-size);

  &__input {
    color: var(--font-color) !important;
    background-color: transparent !important;
  }

  &__control {
    box-shadow: none !important;
    border: 1px solid var(--input-border-color) !important;
    background-color: var(--input-background-color) !important;

    &:hover {
      border-color: var(--primary-color) !important;
    }
  }

  &__control--menu-is-open {
    border: 1px solid var(--primary-color) !important;
  }

  &__control--is-focused {
    background-color: var(--placeholder-background-color) !important;
    border: 1px solid var(--primary-color) !important;
  }

  &__placeholder {
    color: var(--gray) !important;
  }

  &__menu {
    margin-top: 0 !important;
    background-color: var(--block-background-color) !important;
    color: var(--font-color) !important;
    z-index: 8 !important;
  }

  &__menu-notice {
    color: var(--font-color) !important;
  }

  &__option {
    display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;

    .fas,
    .far {
      cursor: pointer !important;

      &:hover {
        color: var(--primary-color) !important;
      }
    }
  }

  &__option--is-focused {
    background-color: var(--dropdown-hover-background) !important;
  }

  &__option--is-selected {
    &:hover {
      opacity: 0.7;
    }

    background-color: var(--primary-color) !important;
  }

  &__option {
    cursor: pointer;
    transition: 0.1s;

    .pool-option {
      margin-left: 20px;
      max-width: 110px;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .option-header {
      filter: invert(50%);
      font-weight: 600;
      font-size: 16px;
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: center;
    }
  }

  &__single-value {
    color: var(--font-color) !important;
  }

  &__indicators &__indicator-separator {
    display: none;
  }

  &--is-disabled {
    opacity: 0.6;
  }

  &__multi-value {
    background-color: var(--select-multi-value-color) !important;

    &__label {
      color: var(--font-color) !important;

      .guest-option {
        transition: 0.1s;
        cursor: pointer;

        &:hover {
          opacity: 0.5;
        }
      }
    }
  }

  &.menu-disable {
    .custom-select__menu {
      display: none !important;
    }
  }

  &.is-danger {
    .custom-select__control {
      border-color: var(--border-danger) !important;
    }
  }

  &.in-modal {
    .custom-select {
      &__menu {
        position: inherit;
        text-align: left;
      }
    }
  }

  &.pagination {
    font-size: calc(100% - 3px);

    .custom-select {
      &__control {
        min-height: 22px;
        height: 22px;
        border-radius: unset;
        background-color: transparent !important;
      }

      &__value-container,
      &__indicators {
        position: static;
        height: 22px;

        svg {
          fill: var(--font-color);
          width: 18px;
          height: 18px;
        }
      }

      &__dropdown-indicator {
        padding: 0;
        padding-left: 0;
        margin-bottom: 2px;
      }

      &__input {
        display: none;
      }

      &__menu {
        border-radius: unset;
      }

      &__menu-list {
        padding: 0;
      }

      &__option {
        padding: 2px 0;
      }
    }
  }

  &.with-tags {
    .custom-select__multi-value {
      &__label,
      &__remove {
        color: var(--white) !important;
      }

      &__label {
        border-radius: 4px;
        border-top-right-radius: unset;
        border-bottom-right-radius: unset;
      }

      &__remove {
        border-radius: 4px;
        border-top-left-radius: unset;
        border-bottom-left-radius: unset;
      }
    }

    .tag-option {
      display: flex;
      align-items: center;

      .color {
        border-radius: 50%;
        width: 10px;
        height: 10px;
        margin-left: 10px;
      }
    }
  }
}

.custom-select-dashboard {
  &__control {
    cursor: pointer !important;
    background-color: var(--secondary-btn-background-color) !important;
    border-color: transparent !important;
    border-right: 1px solid var(--light-gray) !important;
    border-top-right-radius: unset !important;
    border-bottom-right-radius: unset !important;

    &:hover {
      border-color: var(--primary-color) !important;
    }
  }
}

.custom-select-filter-right {
  &__control {
    cursor: pointer !important;
    background-color: var(--secondary-btn-background-color) !important;
    border-color: transparent !important;
    border-top-left-radius: unset !important;
    border-bottom-left-radius: unset !important;

    &:hover {
      border-color: var(--primary-color) !important;
    }
  }
}

.custom-select-filter-left {
  &__control {
    cursor: pointer !important;
    background-color: var(--secondary-btn-background-color) !important;
    border-color: transparent !important;
    border-top-right-radius: unset !important;
    border-bottom-right-radius: unset !important;
    border-right: 1px solid var(--light-gray) !important;

    &:hover {
      border-color: var(--primary-color) !important;
    }
  }
}

.custom-select-filter {
  &__control {
    cursor: pointer !important;
    background-color: var(--secondary-btn-background-color) !important;
    border-color: transparent !important;

    &:hover {
      border-color: var(--primary-color) !important;
    }
  }
}

.custom-signature {
  border: 1px solid var(--input-border-color);
  background-color: var(--input-background-color);
  border-radius: 4px;
  padding: 1px;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 0;
  }

  &.is-danger {
    border-color: var(--border-danger);
  }

  &.white-background {
    background: transparent;
    height: auto;
    max-width: 100% !important;
  }
}

.email-management {
  &.custom-signature {
    height: auto;
  }
}

.w-100 {
  width: 100%;
}

.is-clickable {
  cursor: pointer;
}

@media only screen and (max-width: 1100px) {
  .columns {
    margin-left: 0;
    margin-right: 0;
  }
}
[data-tooltip]:hover::before {
  font-size: 12px;
}

.d-flex {
  display: flex;
}
.align-items-center {
  align-items: center;
}
.justify-content-between {
  justify-content: space-between;
}
.justify-content-end {
  justify-content: flex-end;
}
.justify-content-around {
  justify-content: space-around;
}
.justify-content-center {
  justify-content: center;
}
// TODO Fix this at a later date for russell
/* Hide the default scrollbar */
// ::-webkit-scrollbar {
//   display: none;
// }

// /* Style the scrollbar container */
// ::-webkit-scrollbar {
//   width: 8px;
//   background-color: #f1f1f1;
//   border-radius: 6px;

//   /* Firefox scrollbar customization (optional) */
//   scrollbar-width: thin; /* auto, thin, or none */
//   scrollbar-color: #888 #f1f1f1;
// }

// /* Style the scrollbar thumb */
// ::-webkit-scrollbar-thumb {
//   background-color: #888;
//   border-radius: 6px;

//   /* Firefox scrollbar customization (optional) */
//   scrollbar-width: thin; /* auto, thin, or none */
//   scrollbar-color: #888 #f1f1f1;
// }

// /* Style the scrollbar track */
// ::-webkit-scrollbar-track {
//   background-color: #f1f1f1;

//   /* Firefox scrollbar customization (optional) */
//   scrollbar-width: thin; /* auto, thin, or none */
//   scrollbar-color: #888 #f1f1f1;
// }

// /* Hide the scrollbar buttons */
// ::-webkit-scrollbar-button {
//   display: none;
// }

.badge {
  color: white;
  margin: 0 5px;
  padding: 4px;
  font-size: 11px;
  border-radius: 4px;
  font-weight: 500;

  &-success {
    background-color: green;
  }
  &-warning {
    background-color: orange;
  }
  &-error {
    background-color: red;
  }
}
.react-resizable-hide .react-resizable-handle {
  display: none !important;
}

.dropdown-content {
  box-shadow: var(--box-shadow);
}

.link {
  cursor: pointer;
  transition: 0.1s;

  &:hover {
    color: var(--primary-color);
  }

  &.link--active {
    color: var(--primary-color);
  }
  &.link--import {
    font-weight: 500;
    font-size: 12px;
    // color: green;
  }
}
.badgeStatus {
  border: 1px solid;
  padding: 5px 15px;
  border-radius: 25px;
  font-size: 12px;
  margin-bottom: 10px;
}

.badgeStatusActive {
  border-color: #439C3E;
  background-color: #439C3E;
  color: white;
}

.badgeStatusNotActive {
  border-color: #ccc;
  background-color: #ccc;
}

.th-sorting:hover {
  color: var(--primary-color) !important;
  cursor: pointer;
}

:root {
  --bulma-border: var(--border-color);
  --bulma-strong-color: var(--dark-gray-20);
  --bulma-pre-background: var(--light-gray);
  --bulma-pre: black;
  --bulma-hr-background-color: var(--light-gray);
}
.control {
  --bulma-input-icon-focus-color: var(--primary-color) !important;
}
