@use "sass:color";

$font-family: 'Nunito', 'Poppins', 'Roboto', sans-serif;
$mad-green: #48b958;
$mad-blue: #272e5b;
$plan-card-border: 0.3rem solid $mad-green;
$lightgray: #6c757d;
$border-lightgray: #b7b7b7;
$danger: #ff6685;

@mixin billing-button {
  font-family: $font-family;
  color: #fff;
  background-color: $mad-green;
  border-color: $mad-green;
  transition: background-color ease-in 120ms;

  &:disabled {
    background-color: color.adjust($mad-green, $lightness: 12%);
    cursor: default;
    pointer-events: none;
  }

  &:hover {
    background-color: color.adjust($mad-green, $lightness: -4%);
  }

  &:active {
    background-color: color.adjust($mad-green, $lightness: 2%);
  }
}
