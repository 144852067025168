.table-container {
  border: 1px solid var(--table-header-background-color);
  &.total {
    margin-top: 42px !important;
  }
  &.no-margin-top {
    margin-top: 0 !important;
  }
  &.no-margin-bottom {
    margin-bottom: 0;
  }
  &.is-report {
    margin-top: 20px;
  }
}

.table.custom-table {
  background-color: var(--table-header-background-color);
  .is-checkradio[type='checkbox'] + label {
    margin: 0;
    padding-right: 0;
    padding-left: 2rem;
  }
  thead,
  tfoot {
    background-color: var(--table-header-background-color);

    tr {
      th {
        border: none;
        border-right: 1px solid var(--table-head-border);
        font-size: 13px;
        padding: 13px 20px;
        white-space: nowrap;
        color: var(--table-color);
        &:last-of-type {
          border: none;
        }
      }
    }
  }

  tbody {
    tr {
      td {
        border-color: var(--table-border);
        border-right: 1px solid var(--table-border);
        &:last-of-type {
          border-right: 0;
        }
      }
    }
  }

  td {
    font-size: 12px;
    padding: 8px 20px;
    white-space: nowrap;
    color: var(--table-color);
    background-color: var(--table-item-striped-background-color);
  }

  &.is-policies {
    tbody {
      tr {
        cursor: pointer;
        td {
          .custom-select {
            width: 150px;
            &__control,
            &__indicators {
              height: 25px;
              min-height: 25px;
            }
            &__value-container {
              position: static;
            }
            &__menu-list {
              max-height: 120px;
            }
          }
          &:last-of-type {
            .table-dropdown {
              .dropped-list.policies {
                top: -10px;
              }
            }
          }
        }
        &:nth-last-child(-n + 2) {
          td {
            .custom-select__menu {
              top: auto;
              bottom: 100%;
            }
          }
        }
        &:nth-child(-n + 2) {
          td {
            .custom-select__menu {
              top: auto;
              bottom: unset;
            }
          }
        }
        &:last-of-type {
          border-bottom: 1px solid var(--table-border);
        }
      }
    }
  }

  &.small-paddings {
    thead,
    tfoot {
      th {
        padding: 7px 5px;
      }
    }
    td {
      padding: 4px 5px;
    }
  }
  &.is-striped-all {
    tbody {
      tr td.pre-wrap {
        white-space: pre-wrap;
      }

      tr:not(.is-selected):nth-child(even) {
        td {
          background-color: var(--tr-odd-background);

          .custom-select__control {
            background-color: var(--tr-even-background) !important;
          }
        }
      }

      tr:not(.is-selected):nth-child(odd) {
        td {
          background-color: var(--tr-odd-background);

          .custom-select__control {
            background-color: var(--tr-even-background) !important;
          }
        }
      }
    }
  }
  &.is-striped {
    tbody {
      tr td.pre-wrap {
        white-space: pre-wrap;
      }
      tr:not(.is-selected):nth-child(even) {
        td {
          background-color: var(--tr-even-background);
          .custom-select__control {
            background-color: var(--tr-odd-background) !important;
          }
        }
      }
      tr:not(.is-selected):nth-child(odd) {
        td {
          background-color: var(--tr-odd-background);
          .custom-select__control {
            background-color: var(--tr-even-background) !important;
          }
        }
      }
    }
  }
  &.pre-wrap-all {
    tbody {
      tr td {
        white-space: pre-wrap;
        word-break: break-all;
      }
    }
  }
  &.is-report {
    background-color: unset;
    &.is-capture {
      tbody {
        -ms-overflow-style: none;
        tr:not(.is-selected):nth-child(even) {
          background-color: var(--table-item-striped-background-color);
        }
        tr.gray {
          background-color: var(--tr-even-background);
          td {
            border-right: 1px solid var(--table-head-border);
            background-color: transparent;
          }
        }
        tr {
          td.gray {
            border-right: 1px solid var(--table-head-border);
            background-color: var(--tr-even-background);
          }
        }
        &::-webkit-scrollbar {
          display: none;
        }
      }
    }
  }
  &.is-import {
    border: 1px solid var(--table-item-background-color);
    tr {
      border-top: 1px solid var(--table-item-background-color);
      vertical-align: center;

      th {
        vertical-align: middle;
        padding: 10px;
      }
      td {
        padding: 10px;
        .edit {
          cursor: pointer;
          transition: 0.1s;
          text-decoration: underline;
          font-weight: 500;
          &:hover {
            color: var(--primary-color);
          }
        }
        &.file-field {
          p {
            max-width: 200px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
        span {
          font-size: 20px;
          color: var(--primary-color);
        }
        .custom-select {
          min-width: 315px;
        }
      }
    }
  }
  &.is-dialer-table,
  &.is-products-management,
  &.is-products-table,
  &.is-company-table,
  &.is-email-management-table,
  &.is-reports-table,
  &.is-transfers-table {
    tbody {
      tr {
        td {
          .table-dropdown {
            position: relative;
            width: fit-content;
            cursor: pointer;
            i {
              padding: 5px;
              border-radius: 50%;
              border: 1px solid transparent;
              transition: 0.1s;
              &:hover {
                color: var(--primary-color);
                box-shadow: var(--box-shadow);
                border-color: var(--primary-color);
              }
            }
            .dropped-list {
              z-index: 1;
              position: absolute;
              background-color: var(--block-background-color);
              top: 25px;
              right: 0;
              box-shadow: var(--box-shadow);
              visibility: hidden;
              opacity: 0;
              p {
                font-size: 14px;
                text-align: start;
                width: 100px;
                padding: 0 10px;
                cursor: pointer;
                transition: 0.1s;
                &:hover {
                  background-color: var(--navbar-item-background-hover);
                }
              }
            }
            &:hover {
              .dropped-list {
                visibility: visible;
                opacity: 1;
              }
            }
          }
        }
        &:nth-last-child(-n + 3) {
          td {
            .table-dropdown {
              .dropped-list {
                top: -53px;
              }
            }
          }
        }
      }
    }
  }
  &.hovered {
    thead {
      tr {
        th {
          cursor: pointer !important;
          transition: 0.1s;
          &:hover {
            color: var(--primary-color);
            opacity: 0.7;
          }
          &.active {
            color: var(--primary-color);
            &:hover {
              opacity: 0.4;
            }
          }
        }
      }
    }
  }
  &.first-field-hover {
    thead {
      tr {
        th:first-child {
          cursor: pointer !important;
          transition: 0.1s;
          &:hover {
            color: var(--primary-color);
            opacity: 0.7;
          }
          &.active {
            color: var(--primary-color);
            &:hover {
              opacity: 0.4;
            }
          }
        }
      }
    }
  }
  &.last-field-hover-disabled {
    thead {
      tr {
        th:not(:last-of-type) {
          cursor: pointer;
          transition: 0.1s;
          &:hover {
            color: var(--primary-color);
            opacity: 0.7;
          }
          &.active {
            color: var(--primary-color);
            &:hover {
              opacity: 0.4;
            }
          }
        }
      }
    }
  }
  &.last-two-fields-hover-disabled {
    thead {
      tr {
        th:nth-last-child(n + 3) {
          cursor: pointer;
          transition: 0.1s;
          &:hover {
            color: var(--primary-color);
            opacity: 0.7;
          }
          &.active {
            color: var(--primary-color);
            &:hover {
              opacity: 0.4;
            }
          }
        }
      }
    }
  }
  &.is-company-table {
    tbody {
      tr {
        &:nth-last-child(-n + 3) {
          td {
            .table-dropdown {
              .dropped-list {
                top: -53px;
              }
            }
          }
        }
      }
    }
    &.small-dropdown {
      tbody {
        tr:nth-last-child(-n + 3) {
          td .table-dropdown {
            .dropped-list {
              top: -20px;
            }
          }
        }
      }
    }
  }
  &.is-capture {
    td {
      background-color: unset;
    }
    thead {
      tr {
        th {
          border: none;
          padding: 13px 7px;
        }
      }
    }
    tbody {
      border-top: 1px solid var(--table-border);
      tr {
        background-color: var(--block-background-color);
        &:not(.is-selected):nth-child(even) {
          background-color: unset !important;
          td {
            background-color: var(--block-background-color);
          }
        }
      }
    }
  }
  &.fixed-header {
    thead {
      position: fixed;
      top: 49px;
      border: 1px solid var(--table-header-background-color);
    }
  }
}

.table.merge-table {
  display: flex;
  margin-top: 15px;
  margin-bottom: 50px;
  border: 1px solid var(--search-background);
  color: var(--font-color);
  .field {
    display: flex;
    align-items: center;
    align-content: center;
    margin: 0;
    p {
      padding: 0;
      margin: 0;
    }
  }
  label {
    margin: 0 !important;
    display: flex !important;
  }
  .grey {
    background-color: var(--search-background);
  }
  .bold {
    font-weight: 500;
  }
  p {
    word-break: break-word;
    transition: 0.1s;
    &:not(.email) {
      text-transform: capitalize;
    }
  }
  tbody {
    width: 100%;
    tr {
      width: 100%;
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid var(--search-background);
      background-color: var(--table-item-striped-background-color);
      &:last-of-type {
        border-bottom: none;
      }
      td {
        color: var(--text-color);
        border: none;
        padding: 10px 25px;
        width: calc(100% / 5 - 2px);
        &.padded {
          p {
            padding-left: 25px;
          }
        }
        &.listed {
          p {
            margin-right: 5px;
            word-break: inherit;
          }
          label {
            max-width: 200px;
            display: flex;
            flex-wrap: wrap;
          }
        }
      }
    }
  }
}

.billing-table {
  .description {
    color: var(--gray);
    margin-bottom: 10px;
  }
}

.table {
  &.is-distributed {
    thead {
      tr {
        th:last-of-type {
          cursor: default;
          &:hover {
            opacity: 1;
          }
        }
      }
    }
  }
  &.is-capture {
    thead {
      tr {
        th {
          cursor: pointer;
          transition: 0.1s;
          &:hover {
            color: var(--primary-color);
            opacity: 0.7;
          }
          &.active {
            color: var(--primary-color);
          }
        }
      }
    }
  }
  &is-report {
    margin-top: 20px;
  }
}

@media only screen and (max-width: (1240px - 1px)) {
  .table.custom-table {
    &.fixed-header {
      thead {
        top: 46px;
      }
    }
  }
}
