.advanced-wrapper {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 1.5rem;
  margin-bottom: 3rem;

  &.d-none {
    display: none;
  }
  .search-option {
    position: relative;
    border-radius: 8px;
    padding: 10px 14px;
    cursor: pointer;
    transition: 0.1s;
    &:hover {
      opacity: 0.8;
    }
    &:after {
      position: absolute;
      content: '';
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border-radius: 8px;
      background-color: var(--primary-color);
      opacity: 0.2;
    }
    p {
      color: var(--primary-color);
      display: flex;
      align-items: center;
      font-size: calc(100% - 1px);
      white-space: pre-wrap;

      span {
        margin-right: 5px;
      }
      b {
        margin-right: 5px;
      }
    }
    &.disabled {
      border: 1px solid var(--light-gray);
      p {
        color: var(--font-color);
      }
      &:after {
        background-color: transparent;
      }
    }
  }
  .clear-search {
    color: var(--primary-color);
    cursor: pointer;
    transition: 0.1s;
    i {
      &.fa-trash-alt {
        font-size: 17px;
      }
      &.fa-save {
        padding-top: 3px;
        font-size: 19px;
      }
      &:hover {
        opacity: 0.5;
      }
    }
  }
}

.columns-wrapper {
  .left-sidebar {
    position: absolute;
    top: 100px;

    ul {
      li {
        margin-bottom: 30px;
        a p {
          transition: 0.1s;
          &:hover {
            color: var(--primary-color);
          }
        }
        .active {
          position: relative;

          p {
            color: var(--primary-color);
          }
        }
      }
    }
  }

  .column {
    .main-actions {
      margin-top: 40px;
      margin-bottom: 20px;
    }
  }
  .check-all {
    padding: 0 12px 10px 3rem;
    border: 1px solid transparent;
    border-top: none;
    .checkbox-section {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      align-items: center;
      .field {
        margin-bottom: 11px;
        width: fit-content;
        &.dashed {
          position: relative;
          &:before {
            position: absolute;
            top: 12px;
            left: 5px;
            content: '';
            height: 1px;
            width: 13px;
            z-index: 2;
            background-color: var(--primary-color);
          }
        }
        &.all {
          &:before {
            display: none;
          }
        }
      }
      .actions-wrapper {
        width: 76%;
        flex-grow: 2;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        p {
          cursor: pointer;
          color: var(--font-color);
          font-weight: 500;
          transition: 0.1s;
          &:hover {
            color: var(--primary-color);
          }
          &.disabled {
            opacity: 0.5;
            cursor: not-allowed;
          }
        }
        .tag-change {
          position: relative;
          .tag-wrapper {
            right: unset;
            left: 0;
            .field {
              width: 100%;
            }
          }
        }
        &.small {
          justify-content: flex-start;
          .action {
            width: 100px;
          }
        }
      }
      .switcherPagination {
        margin-bottom: 8px;
      }
    }
    &.fixed {
      position: fixed;
      top: 48px;
      padding: 0;
      width: 100%;
      left: 0;
      z-index: 96;
      border-color: var(--navbar-border);
      background-color: var(--table-item-striped-background-color);
      animation: fadeIn 0.3s;
      .checkbox-section {
        max-width: 1242px;
        margin: 0 auto;
        padding: 0.5rem 0;
        padding-bottom: 0.7rem;
        padding-left: 3rem;
        .field {
          margin-bottom: 0;
        }
        .switcherPagination {
          margin-bottom: 0;
        }
        .actions-wrapper {
          .tag-change {
            margin-bottom: 0;
          }
        }
      }
    }
    &.shifted {
      top: 80px;
    }
  }
  .select-all-message {
    transition: 0.1s;
    width: 100%;
    text-align: center;
    background-color: var(--table-item-background-color);
    border-radius: 4px;
    color: var(--gray);
    height: 0;
    opacity: 0;
    visibility: hidden;
    &.visible {
      height: auto;
      padding: 11px 15px;
      opacity: 1;
      visibility: visible;
      margin-bottom: 16px;
    }
    i {
      cursor: pointer;
      color: var(--primary-color);
      font-weight: 500;
      font-style: normal;
      transition: 0.1s;
      &:hover {
        opacity: 0.5;
      }
    }
    &.fixed {
      position: fixed;
      top: 84px;
      width: 100%;
      left: 0;
      margin: 0 auto;
      z-index: 4;
      border-radius: unset;
    }
    .mbri-close {
      color: var(--primary-color);
      position: absolute;
      margin: 3px 10px 0 20px;
      cursor: pointer;
    }
  }
}

.bottom-content {
  .move-to-top {
    z-index: 6;
    position: fixed;
    bottom: 35px;
    right: 70px;
    display: flex;
    justify-content: flex-end;
    padding: 0 20px;
    span {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: var(--primary-color);
      color: var(--white);
      border-radius: 50%;
      padding: 5px;
      width: 40px;
      height: 40px;
      box-shadow: var(--round-button-shadow);
      cursor: pointer;
      transition: 0.1s;
      font-weight: bold;
      &:hover {
        box-shadow: none;
      }
    }
  }
}
@media only screen and (max-width: 1300px) {
  .columns-wrapper {
    .check-all {
      &.fixed {
        .checkbox-section {
          max-width: 1152px;
        }
      }
    }

    .left-sidebar {
      left: -34px;
    }
  }
}

@media only screen and (max-width: 1210px) {
  .columns-wrapper {
    .check-all {
      .checkbox-section {
        .actions-wrapper {
          flex-basis: 92%;
          flex-grow: 1;
        }
      }
      &.fixed {
        .checkbox-section {
          padding: 0.5rem 2rem;
          .actions-wrapper {
            width: 75%;
          }
        }
      }
    }
    .select-all-message {
      &.fixed {
        top: 78px;
      }
    }
  }
}

@media only screen and (max-width: 1024px) {
  .columns-wrapper {
    .left-sidebar {
      position: static;
      padding-top: 15px;
    }
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
