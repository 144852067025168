
@media print {
    body {
      print-color-adjust: exact;
      -webkit-print-color-adjust: exact;
    }
    
    header, 
    #cal-side-view, 
    #cal-print-btn, 
    #cal-add-btn,
    .fc-button-group, 
    .fc-header-toolbar {
        display: none;
    }

    .notification-container {
      display: none;
    }

}

// @media (max-width: 1250px) {
//   .mobile-hide {
//     display: none !important;
//   }
// }