.DialerWrapper {
  height: 0;
  width: 0;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  position: absolute;
  z-index: 999;

  color: black;

  .DialerPhoneWrapper {
    bottom: 160px;
    right: 30px;
    height: 600px;
    position: fixed;
    width: 325px;
    background: white;
    box-shadow: 1px 3px 12px 6px var(--dialer-main-wrapper-border);
    display: block;
    visibility: visible;
    border-radius: 20px;
  }

  .DialerLaunchIconWrapper {
    bottom: 80px;
    right: 30px;
    position: fixed;
    width: 70px;
    height: 70px;
    text-align: center;
    line-height: 70px;
    font-size: 25px;
    border-radius: 100%;
    background: var(--dialer-main-btn-bg);
    color: var(--dialer-main-btn-color);
    cursor: pointer;
  }
}

@media print {
  .DialerLaunchIconWrapper {
    display: none;
  }
}

@media (max-width: 768px) {
  .DialerWrapper {
    height: 0;
    width: 0;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    position: absolute;
    z-index: 999;

    color: black;

    .DialerPhoneWrapper {
      bottom: 160px;
      right: 30px;
      height: 400px;
      position: fixed;
      width: 216px;
      background: white;
      box-shadow: 1px 3px 12px 6px var(--dialer-main-wrapper-border);
      display: block;
      visibility: visible;
      border-radius: 20px;
    }

    .DialerLaunchIconWrapper {
      bottom: 80px;
      right: 30px;
      position: fixed;
      width: 70px;
      height: 70px;
      text-align: center;
      line-height: 70px;
      font-size: 25px;
      border-radius: 100%;
      background: var(--dialer-main-btn-bg);
      color: var(--dialer-main-btn-color);
      cursor: pointer;
    }
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .DialerWrapper {
    height: 0;
    width: 0;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    position: absolute;
    z-index: 999;

    color: black;

    .DialerPhoneWrapper {
      bottom: 160px;
      right: 30px;
      height: 500px;
      position: fixed;
      width: 270.83px;
      background: white;
      box-shadow: 1px 3px 12px 6px var(--dialer-main-wrapper-border);
      display: block;
      visibility: visible;
      border-radius: 20px;
    }

    .DialerLaunchIconWrapper {
      bottom: 80px;
      right: 30px;
      position: fixed;
      width: 70px;
      height: 70px;
      text-align: center;
      line-height: 70px;
      font-size: 25px;
      border-radius: 100%;
      background: var(--dialer-main-btn-bg);
      color: var(--dialer-main-btn-color);
      cursor: pointer;
    }
  }
}

@media (max-height: 768px) {
  .DialerWrapper {
    height: 0;
    width: 0;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    position: absolute;
    z-index: 999;

    color: black;

    .DialerPhoneWrapper {
      bottom: 160px;
      right: 30px;
      height: 400px;
      position: fixed;
      width: 216px;
      background: white;
      box-shadow: 1px 3px 12px 6px var(--dialer-main-wrapper-border);
      display: block;
      visibility: visible;
      border-radius: 20px;
    }

    .DialerLaunchIconWrapper {
      bottom: 80px;
      right: 30px;
      position: fixed;
      width: 70px;
      height: 70px;
      text-align: center;
      line-height: 70px;
      font-size: 25px;
      border-radius: 100%;
      background: var(--dialer-main-btn-bg);
      color: var(--dialer-main-btn-color);
      cursor: pointer;
    }
  }
}

@media (min-height: 769px) and (max-height: 1024px) {
  .DialerWrapper {
    height: 0;
    width: 0;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    position: absolute;
    z-index: 999;

    color: black;

    .DialerPhoneWrapper {
      bottom: 160px;
      right: 30px;
      height: 500px;
      position: fixed;
      width: 270.83px;
      background: white;
      box-shadow: 1px 3px 12px 6px var(--dialer-main-wrapper-border);
      display: block;
      visibility: visible;
      border-radius: 20px;
    }

    .DialerLaunchIconWrapper {
      bottom: 80px;
      right: 30px;
      position: fixed;
      width: 70px;
      height: 70px;
      text-align: center;
      line-height: 70px;
      font-size: 25px;
      border-radius: 100%;
      background: var(--dialer-main-btn-bg);
      color: var(--dialer-main-btn-color);
      cursor: pointer;
    }
  }
}
