.section {
  .react-calendar {
    background: var(--block-background-color);
    border: none;
    margin-bottom: 30px;
    &__navigation {
      position: relative;
      button:enabled {
        background-color: var(--block-background-color);
        color: var(--font-color);
        &:hover,
        &:focus {
          z-index: 2;
        }
      }
      &__label {
        font-size: 13px;
        max-width: 100px;
      }
      &__prev2-button,
      &__next2-button {
        display: none;
      }
      &__prev-button {
        position: absolute;
        top: 5px;
        right: 25px;
      }
      &__next-button {
        position: absolute;
        top: 5px;
        right: 0;
      }
      &__arrow {
        z-index: 1;
        transition: 0.1s;
        font-size: 16px;
        color: var(--calendar-text-color);
        transition: 0.1s;
        &:enabled:hover,
        &:enabled:focus {
          background-color: transparent;
          color: var(--primary-color);
          &:after {
            box-shadow: var(--box-shadow);
          }
        }
        &:after {
          transition: 0.1s;
          z-index: -1;
          position: absolute;
          top: 3px;
          left: 50%;
          transform: translateX(-50%);
          content: '';
          background-color: var(--gray-bg-color);
          border-radius: 50%;
          width: 16px;
          height: 16px;
        }
      }
    }
    &__month-view {
      &__weekdays {
        opacity: 0.5;
        text-decoration: none;
        abbr {
          opacity: 0.65;
          font-weight: 500;
          line-height: 1.36;
          font-size: 14px;
          text-decoration: none;
        }
      }
    }
    &__month-view {
      &__days {
        &__day {
          &:enabled:hover {
            background: transparent;
          }
          &--neighboringMonth {
            opacity: 0.4;
          }
        }
      }
    }
    &__tile {
      outline: none;
      color: var(--calendar-text-color);
      background-color: transparent;
      &:enabled:hover,
      &:enabled:focus {
        background-color: var(--block-background-color);
      }
      abbr {
        position: relative;
        z-index: 2;
        transition: 0.1s;
        &:after {
          transition: 0.1s;
          content: '';
          z-index: -1;
          transition: 0.1s;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 28px;
          height: 28px;
          background-color: var(--primary-color);
          border-radius: 50%;
          visibility: hidden;
          opacity: 0;
        }
      }
      &:hover {
        abbr {
          color: var(--font-color);
          &:after {
            opacity: 0.5;
            visibility: visible;
          }
        }
      }
      &--hasActive {
        background: var(--primary-color);
        color: var(--white);
        transition: 0.1s;
        &:enabled {
          &:hover,
          &:focus {
            background: var(--primary-color);
            opacity: 0.5;
          }
        }
      }
      &--now {
        abbr {
          color: var(--white);
          &:after {
            visibility: visible;
            opacity: 1;
            background-color: var(--placeholder-background-color);
          }
          &:hover {
            color: var(--primary-color);
            &:after {
              opacity: 0.7;
            }
          }
        }
      }
      &--active {
        transition: 0.1s;
        background: transparent;
        abbr {
          color: var(--white);
          &:after {
            visibility: visible;
            opacity: 1;
            background-color: var(--primary-color);
          }
          &:hover {
            &:after {
              opacity: 0.5;
            }
          }
        }
        &:enabled:hover {
          background: transparent;
        }
        &:enabled:focus {
          background: transparent;
        }
        z-index: 2;
      }
    }
  }
}
