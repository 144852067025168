.fa-phone-burner:before {
  background-image: url('/icons/phone-burner.png');
  background-size: 20px;
  display: inline-block;
  width: 20px;
  height: 20px;
  content: '';
}

.fa-caui:before {
  background-image: url('/caui/voice-agent-icon.png');
  background-size: 20px;
  display: inline-block;
  width: 20px;
  height: 20px;
  content: '';
}

.fa-dialer:before {
  background-image: url('/icons/dialer.png');
  background-size: 20px;
  display: inline-block;
  width: 20px;
  height: 20px;
  content: '';
}

.unread-notifications-circle-nav {
  position: absolute;
  top: -5px;
  left: 12px;
  width: 8px;
  height: 8px;
  background: var(--primary-color);
  border-radius: 50%;
}
