
:root {
  --fc-list-event-dot-width: 10px;
  --fc-list-event-hover-bg-color: #f5f5f5;
}

#calendar-view-wrapper .fc-view-harness .fc-scroller .fc-list-table .fc-list-day {
  background: var(--fc-page-bg-color, #f7f7f7);
  display: contents !important;
  // position: relative !important;
  // left: 0 !important;
}

#calendar-view-wrapper .fc-theme-standard td, .fc-theme-standard th {
  border: 1px solid #ededed;
  color: var(--fc-page-text-color, #000);
  font-family: 'Roboto' !important;
  font-weight: 100;
}


#calendar-view-wrapper .fc-list-table thead {
  background: var(--fc-page-bg-color, #f7f7f7);
  display: table-header-group !important;
  position: relative !important;
  left: 0 !important;
}

#calendar-view-wrapper .fc-list-table thead tr th {
  padding-left: 10px !important;
  font-weight: 100;
}

#calendar-view-wrapper .fc-view-harness .fc-scroller .fc-list-table .fc-list-event {
  display: table-row;
}
#calendar-view-wrapper .fc-theme-standard .fc-list {
  border: .25px solid #ddd;
}

#calendar-view-wrapper .fc-theme-standard td {
  border: .25px solid #ddd;
}


#calendar-view-wrapper .fc {
/* message when no events */
}

#calendar-view-wrapper .fc .fc-list-empty {
  background-color: var(--fc-neutral-bg-color, rgba(208, 208, 208, 0.3));
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center; /* vertically aligns fc-list-empty-inner */
}

#calendar-view-wrapper .fc .fc-list-empty-cushion {
  margin: 5em 0;
}


#calendar-view-wrapper .fc {

  /* table within the scroller */
  /* ---------------------------------------------------------------------------------------------------- */

}


#calendar-view-wrapper .fc .fc-list-table {
  width: 100%;
  border-style: hidden; /* kill outer border on theme */
}


#calendar-view-wrapper .fc .fc-list-table tr > * {
  border-left: 0;
  border-right: 0;
}

#calendar-view-wrapper .fc .fc-list-sticky .fc-list-day > * { /* the cells */
  position: sticky;
  top: 0;
  background: var(--fc-page-bg-color, #f7f7f7); /* for when headers are styled to be transparent and sticky */
}

#calendar-view-wrapper .fc {

  /* only exists for aria reasons, hide for non-screen-readers */

}

#calendar-view-wrapper .fc .fc-list-table thead {
  position: absolute;
  left: -10000px;
}

#calendar-view-wrapper .fc {

  /* the table's border-style:hidden gets confused by hidden thead. force-hide top border of first cell */

}

#calendar-view-wrapper .fc .fc-list-table tbody > tr:first-child th {
  border-top: 0;
}

#calendar-view-wrapper .fc .fc-list-table th {
  padding: 0; /* uses an inner-wrapper instead... */
}

#calendar-view-wrapper .fc .fc-list-table td,
#calendar-view-wrapper .fc .fc-list-day-cushion {
  padding: 8px 14px;
}

#calendar-view-wrapper .fc {
  /* date heading rows */
  /* ---------------------------------------------------------------------------------------------------- */
}

#calendar-view-wrapper .fc-button-group {
  font-size: 13px;
}

#calendar-view-wrapper .fc .fc-list-day-cushion:after {
  content: "";
  clear: both;
  display: table; /* clear floating */
}

#calendar-view-wrapper .fc-theme-standard .fc-list-day-cushion {
  background: var(--fc-page-bg-color, #f7f7f7);
}

#calendar-view-wrapper .fc-direction-ltr .fc-list-day-text,
#calendar-view-wrapper .fc-direction-rtl .fc-list-day-side-text {
  float: left;
}

#calendar-view-wrapper .fc-direction-ltr .fc-list-day-side-text,
#calendar-view-wrapper .fc-direction-rtl .fc-list-day-text {
  float: right;
}

/* make the dot closer to the event title */
#calendar-view-wrapper .fc-direction-ltr .fc-list-table .fc-list-event-graphic { padding-right: 0 }
#calendar-view-wrapper .fc-direction-rtl .fc-list-table .fc-list-event-graphic { padding-left: 0 }

#calendar-view-wrapper .fc .fc-list-event.fc-event-forced-url {
  cursor: pointer; /* whole row will seem clickable */
}

#calendar-view-wrapper .fc .fc-list-event:hover td {
  background-color: var(--fc-list-event-hover-bg-color, #f5f5f5);
}

#calendar-view-wrapper .fc {
/* shrink certain cols */
}

#calendar-view-wrapper .fc .fc-list-event-graphic,
#calendar-view-wrapper .fc .fc-list-event-time {
  white-space: nowrap;
  width: 1px;
}

#calendar-view-wrapper .fc .fc-list-event-dot {
  display: inline-block;
  box-sizing: content-box;
  width: 0;
  height: 0;
  border: 5px solid #3788d8;
  border: calc(var(--fc-list-event-dot-width, 10px) / 2) solid var(--fc-event-border-color, #3788d8);
  border-radius: 5px;
  border-radius: calc(var(--fc-list-event-dot-width, 10px) / 2);
}

#calendar-view-wrapper .fc {
  /* reset <a> styling */
}

#calendar-view-wrapper .fc .fc-list-event-title a {
  color: inherit;
  text-decoration: none;
}

#calendar-view-wrapper .fc {
  /* underline link when hovering over any part of row */
}

#calendar-view-wrapper .fc .fc-list-event.fc-event-forced-url:hover a {
  text-decoration: underline;
}

.content {
  height: 100% !important;
}

.fc-col-header,
.fc-timegrid-body {
  width: 100% !important;
  height: 100% !important;
}

.fc-scrollgrid-sync-table {
  width: 100% !important;
  height: 100% !important;
}

.fc-daygrid-body {
  width: 100% !important;
  height: 100% !important;
}

.fc-daygrid-body-balanced {
  width: 100% !important;
  height: 100% !important;
}
