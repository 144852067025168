.tags {
  position: relative;
  .tag-wrapper {
    width: 424px;
    height: auto;
    box-shadow: var(--box-shadow);
    padding: 15px;
    background-color: var(--block-background-color);
    position: absolute;
    top: 25px;
    right: 75px;
    z-index: 3;
    cursor: auto;
    &.new-contact {
      left: 0px;
      top: 38px;
    }
    &.disabled {
      &::before {
        position: absolute;
        top: 0;
        left: 0;
        content: '';
        background-color: var(--white);
        width: 100%;
        height: 100%;
        opacity: 0.5;
        cursor: not-allowed;
        z-index: 1;
      }
    }

    .header {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 5px 4px;
      padding-bottom: 12px;
      p {
        font-family: var(--font-family-headers);
        font-weight: 500;
      }
      span {
        font-size: calc(100% - 2px);
        cursor: pointer;
        transition: 0.1s;
        &:hover {
          color: var(--primary-color);
        }
      }
    }

    .control {
      background-color: var(--block-background-color);
      .icon {
        right: 10px;
        i.tag-icon {
          color: var(--white);
          font-size: 16px;
          border: none;
          color: unset;
          height: 20px !important;
        }
      }
      input {
        &:focus {
          background-color: transparent !important;
        }
      }
    }

    .choose-tag {
      padding-top: 8px;
      .tag-description {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
        .description {
          font-size: calc(100% - 2px);
          color: var(--gray);
          &:hover {
            color: var(--gray);
            font-weight: unset;
          }
        }
      }
      .tag-value {
        overflow-wrap: break-word;
        margin-bottom: 10px;
        cursor: pointer;
        &:hover {
          color: var(--primary-color);
        }
      }
      .colors-wrapper {
        margin-bottom: 10px;
        display: flex;
        justify-content: space-between;
        .color {
          position: relative;
          border-radius: 50%;
          box-shadow: var(--box-shadow);
          width: 20px;
          height: 20px;
          cursor: pointer;
          transition: 0.1s;
          display: flex;
          justify-content: center;
          align-items: center;
          span {
            font-size: calc(100% - 4px);
            color: var(--white);
            display: none;
          }

          &.active {
            span {
              display: block;
            }
          }
          &:hover {
            opacity: 0.5;
          }
          &.orange {
            background-color: var(--orange);
          }
          &.green {
            background-color: var(--tag-green);
          }
          &.pink {
            background-color: var(--pink);
          }
          &.yellow {
            background-color: var(--yellow-darken-60);
          }
          &.peach {
            background-color: var(--peach-dark);
          }
          &.dark-green {
            background-color: var(--dark-green);
          }
          &.violet {
            background-color: var(--dark-violet);
          }
          &.blue {
            background-color: var(--blue);
          }
          &.light-blue {
            background-color: var(--light-blue);
          }
          &.dark-blue {
            background-color: var(--dark-blue);
          }
        }
      }
    }
    .tag-list {
      margin-top: 10px;
      .tag-item-wrapper {
        max-height: 225px;
        overflow-y: scroll;
        .description {
          color: var(--gray);
          margin-bottom: 10px;
          font-size: calc(100% - 2px);
          cursor: auto;
          &:hover {
            color: var(--gray);
          }
        }
        .tag-item-wrapper-p {
          &:hover {
            color: var(--primary-color) !important;
          }
        }
        .item {
          cursor: pointer;
          display: flex;
          align-items: center;
          padding: 10px 0;
          transition: 0.1s;
          font-weight: unset;
          span,
          i {
            font-weight: 600;
            font-size: 18px;
            color: var(--white);
            display: none;
            margin-right: 10px;
          }
          &:hover {
            color: var(--primary-color);
          }
          &.addTag {
            span {
              color: var(--primary-color);
              display: block;
            }
            &:hover {
              color: var(--primary-color);
            }
          }
          &.removeTag {
            span {
              display: none;
            }
            i {
              color: var(--primary-color);
              display: block;
            }
            &.addTag {
              span {
                display: block;
              }
              i {
                display: none;
              }
            }
          }
          .color {
            height: 10px;
            width: 10px;
            border-radius: 50%;
            margin-left: 10px;
          }
        }
      }
    }
    .apply-wrapper {
      padding-top: 9px;
      button {
        width: 100%;
        max-width: 100%;
        font-weight: 600;
      }
    }
    &.not-editable {
      .tag-list {
        margin-top: 0;
        .tag-item-wrapper {
          .item {
            padding: 12px 15px;
          }
        }
      }
    }
    &.details-tags {
      top: 46px;
      left: -23px;
      width: 353px;
    }
  }
}

@media only screen and (max-width: 1024px) {
  .tags {
    .tag-wrapper {
      right: -100px;
    }
  }
}
