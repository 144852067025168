.DialerPadWrapper {
  color: black;
  .DialerPadDisplay {
    height: 100px;
    margin: auto;
    line-height: 100px;
    font-size: 34px;
    text-align: center;

    background: var(--dialer-main-numbers-bg);
    color: white;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    position: relative;
  }

  .DialerPadDigits {
    padding: 0 25px;
    margin-top: -10px;
    z-index: 99999 !important;
    border-top: 12px solid #ffffff;
    border-radius: 15px;
    position: relative;

    .DialerPadDigitRow {
      display: flex;

      .DialerPadDigit {
        flex-grow: 3;
        margin: auto;
        text-align: center;
        font-size: 24px;
        line-height: 75px;
        height: 75px;
        cursor: pointer;
      }
    }
  }

  .DialerPadActions {
    padding: 0 25px;
    margin-top: 40px;

    .DialerPadActionRow {
      display: flex;

      .DialerPadAction {
        flex-grow: 3;
        margin: auto;
        text-align: center;
        font-size: 24px;
        line-height: 75px;
        height: 75px;
      }

      .DialerPadAction.Call {
        background: green;
        border-radius: 57%;
        line-height: 81px;
        width: 0px;
        height: 72px;

        i {
          font-size: 33px;
          color: white;
        }
      }
    }
  }
}

// @media (max-width: 768px) {
// 	.DialerPadWrapper {
// 	color: black;
// 	.DialerPadDisplay {
// 		height: 100px;
// 		margin: auto;
// 		line-height: 100px;
// 		font-size: 24px;
// 		text-align: center;

// 		// ! New dialer
//     background: var(--dialer-main-numbers-bg);
//     color: white;
//     border-top-right-radius: 20px;
//     border-top-left-radius: 20px;
// 		position: relative;
// 	}

// 	.DialerPadDigits {
// 		padding: 0 25px;
//     margin-top: -10px;
//     z-index: 99999 !important;
//     border-top: 12px solid #ffffff;
//     border-radius: 15px;
//     position: relative;

// 		.DialerPadDigitRow {
// 			display: flex;

// 			.DialerPadDigit {
// 				flex-grow: 3;
// 				margin: auto;
// 				text-align: center;
// 				font-size: 18px;
// 				line-height: 45px;
// 				height: 45px;
// 				cursor: pointer;
// 			}
// 		}
// 	}

// 	.DialerPadActions {
// 		padding: 0 25px;
// 		margin-top: 10px;

// 		.DialerPadActionRow {
// 			display: flex;

// 			.DialerPadAction {
// 				flex-grow: 3;
// 				margin: auto;
// 				text-align: center;
// 				font-size: 18px;
// 				line-height: 45px;
// 				height: 45px;
// 			}

// 			.DialerPadAction.Call {
// 				background: green;
// 				border-radius: 57%;
// 				line-height: 50px;
// 				width: 0px;
// 				height: 43px;

// 				i {
// 					font-size: 24px;
// 					color: white;
// 				}
// 			}
// 		}
// 	}
// }

// }

// @media (min-width: 769px) and (max-width: 1024px) {
// 	.DialerPadWrapper {
// 	color: black;
// 	.DialerPadDisplay {
// 		height: 100px;
// 		margin: auto;
// 		line-height: 100px;
// 		font-size: 34px;
// 		text-align: center;

// 		// ! New dialer
//     background: var(--dialer-main-numbers-bg);
//     color: white;
//     border-top-right-radius: 20px;
//     border-top-left-radius: 20px;
// 		position: relative;
// 	}

// 	.DialerPadDigits {
// 		padding: 0 25px;
//     margin-top: -10px;
//     z-index: 99999 !important;
//     border-top: 12px solid #ffffff;
//     border-radius: 15px;
//     position: relative;

// 		.DialerPadDigitRow {
// 			display: flex;

// 			.DialerPadDigit {
// 				flex-grow: 3;
// 				margin: auto;
// 				text-align: center;
// 				font-size: 20px;
// 				line-height: 55px;
// 				height: 55px;
// 				cursor: pointer;
// 			}
// 		}
// 	}

// 	.DialerPadActions {
// 		padding: 0 25px;
// 		margin-top: 20px;

// 		.DialerPadActionRow {
// 			display: flex;

// 			.DialerPadAction {
// 				flex-grow: 3;
// 				margin: auto;
// 				text-align: center;
// 				font-size: 20px;
// 				line-height: 55px;
// 				height: 55px;
// 			}

// 			.DialerPadAction.Call {
// 				background: green;
// 				border-radius: 57%;
// 				line-height: 60px;
// 				width: 0px;
// 				height: 55px;

// 				i {
// 					font-size: 28px;
// 					color: white;
// 				}
// 			}
// 		}
// 	}
// }

// }

@media (max-height: 768px) {
  .DialerPadWrapper {
    color: black;
    .DialerPadDisplay {
      height: 100px;
      margin: auto;
      line-height: 100px;
      font-size: 24px;
      text-align: center;

      // ! New dialer
      background: var(--dialer-main-numbers-bg);
      color: white;
      border-top-right-radius: 20px;
      border-top-left-radius: 20px;
      position: relative;
    }

    .DialerPadDigits {
      padding: 0 25px;
      margin-top: -10px;
      z-index: 99999 !important;
      border-top: 12px solid #ffffff;
      border-radius: 15px;
      position: relative;

      .DialerPadDigitRow {
        display: flex;

        .DialerPadDigit {
          flex-grow: 3;
          margin: auto;
          text-align: center;
          font-size: 18px;
          line-height: 45px;
          height: 45px;
          cursor: pointer;
        }
      }
    }

    .DialerPadActions {
      padding: 0 25px;
      margin-top: 10px;

      .DialerPadActionRow {
        display: flex;

        .DialerPadAction {
          flex-grow: 3;
          margin: auto;
          text-align: center;
          font-size: 18px;
          line-height: 45px;
          height: 45px;
        }

        .DialerPadAction.Call {
          background: green;
          border-radius: 57%;
          line-height: 50px;
          width: 0px;
          height: 43px;

          i {
            font-size: 24px;
            color: white;
          }
        }
      }
    }
  }
}

@media (min-height: 769px) and (max-height: 1024px) {
  .DialerPadWrapper {
    color: black;
    .DialerPadDisplay {
      height: 100px;
      margin: auto;
      line-height: 100px;
      font-size: 34px;
      text-align: center;

      background: var(--dialer-main-numbers-bg);
      color: white;
      border-top-right-radius: 20px;
      border-top-left-radius: 20px;
      position: relative;
    }

    .DialerPadDigits {
      padding: 0 25px;
      margin-top: -10px;
      z-index: 99999 !important;
      border-top: 12px solid #ffffff;
      border-radius: 15px;
      position: relative;

      .DialerPadDigitRow {
        display: flex;

        .DialerPadDigit {
          flex-grow: 3;
          margin: auto;
          text-align: center;
          font-size: 20px;
          line-height: 55px;
          height: 55px;
          cursor: pointer;
        }
      }
    }

    .DialerPadActions {
      padding: 0 25px;
      margin-top: 20px;

      .DialerPadActionRow {
        display: flex;

        .DialerPadAction {
          flex-grow: 3;
          margin: auto;
          text-align: center;
          font-size: 20px;
          line-height: 55px;
          height: 55px;
        }

        .DialerPadAction.Call {
          background: green;
          border-radius: 57%;
          line-height: 60px;
          width: 0px;
          height: 55px;

          i {
            font-size: 28px;
            color: white;
          }
        }
      }
    }
  }
}
