.notes-wrapper {
  .note {
    color: var(--font-color);
    .header {
      padding: 0 16px;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      align-items: center;
      border-bottom: 1px solid var(--contact-search-background-color);
      padding-bottom: 15px;
      .max-characters-wrapper{
        align-items: flex-end;
        .max-characters {
          color: var(--gray);
          font-size: 10px;
          line-height: 24px;
          padding: 0 10px;
        }
      }
      .icons-wrapper {
        display: flex;
        justify-content: space-between;
        min-width: 100px;
        i {
          font-size: 17px;
          cursor: pointer;
          transition: 0.1s;
          &:hover {
            color: var(--primary-color);
          }
        }
      }
    }
    .content {
      textarea {
        box-shadow: none;
        border: none;
        padding: 16px;
        outline: none;
        height: 265px;
        background-color: var(--block-background-color) !important;
        color: var(--font-color);
        font-size: 15px;
        color: var(--font-color);
        overflow-y: scroll;
        overflow-x: visible;
        &::placeholder {
          color: var(--font-color);
        }
        &:focus {
          border-width: 0px;
          outline: 0;
          -webkit-appearance: none;
          box-shadow: none;
          -moz-box-shadow: none;
          -webkit-box-shadow: none;
        }
      }

      .associated {
        .associated-search {
          position: absolute;
          top: -160px;
          left: 16px;
          width: 425px;
          background-color: var(--block-background-color);
          box-shadow: var(--box-shadow);
          padding: 6px;
        }
        position: relative;
        display: flex;
        align-items: center;
        align-content: center;
        padding: 16px;
        transition: 0.1s;
        // &:hover {
        //   i {
        //     opacity: 0.5;
        //   }
        // }
        // i {
        //   margin-left: 5px;
        //   color: var(--primary-color);
        // }
      }
      border-bottom: 1px solid var(--contact-search-background-color);
    }
    .submit-wrapper {
      padding: 0 16px;
      button {
        max-width: 100px;
      }
    }
  }
}

.full-screen {
  .notes-wrapper {
    .note {
      .content {
        textarea {
          height: 62vh;
        }
        .associated {
          .associated-search {
            left: 16px;
          }
        }
      }
    }
  }
}

.hidden {
  .notes-wrapper {
    .note {
      .header {
        .icons-wrapper {
          i:first-child {
            transition: 0.5s;
            transform: rotate(180deg);
          }
        }
      }
    }
  }
}

.background-default {
  .notes-wrapper .note {
    .content {
      textarea::placeholder {
        color: var(--gray);
      }
    }
  }
}
