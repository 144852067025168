.contact-row {
  .tags {
    .tag-wrapper {
      top: 22px;
      right: -10px;
      .control {
        .icon {
          top: -3px;
        }
      }
    }
  }
  &:last-of-type {
    .tags {
      .tag-wrapper {
        top: -200px;
        right: 0;
      }
    }
  }
}

.contact-modal-wrapper {
  top: calc(100% + 3px);
  width: 248px;
  height: auto;
  box-shadow: var(--box-shadow);
  background-color: var(--block-background-color);
  position: absolute;
  z-index: 95;
  cursor: auto;
}

@media only screen and (max-width: 1366px - 1px) {
  .contact-modal-wrapper {
    width: 240px;
  }
}

@media only screen and (max-width: 1024px) {
  .contact-modal-wrapper {
    &.status,
    &.source,
    &.spouse_first_name {
      top: unset;
      left: 100px;
    }
  }
}
