.fc-toolbar .fc-toolbar-chunk:nth-of-type(1) {
  h2,
  h5 {
    color: var(--font-color);
    font-size: 15px;
  }
}

.fc {
  --fc-now-indicator-color: var(--primary-color);
  table {
    border-collapse: initial !important;
  }
  .fc-toolbar {
    max-width: 85%;
    &.fc-header-toolbar {
      margin-bottom: 11px;
      .fc-toolbar-chunk:nth-of-type(1) {
        min-width: 300px;
        display: flex;
        align-items: center;
      }
    }
  }
}

.fc-media-screen td.fc-today {
  background: transparent;
}

.black {
  span {
    color: var(--black) !important;
  }
}

.fc-listDay-view,
.fc-listWeek-view {
  .fc-list-event-title {
    >a {
      display: none;
    }
  }
}
.fc-customListView-view {
  .fc-list-event-time,
  .fc-list-event-graphic {
    display: none;
  }
  .fc-list-event {
    &:hover {
      .fc-list-event-title {
        background: unset;
      }
    }
    .fc-list-event-title {
      padding: 0 !important;
    }
  }
}
.fc-theme-standard .fc-customListView-view td,
.fc-theme-standard .fc-customListView-view th {
  border: none;
}
.fc-theme-standard .fc-list {
  border-width: 0;
}
.fc-view-harness {
  padding: 10px 9px;
  background-color: var(--block-background-color);
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
  .fc-view.fc-list {
    // border-width: 0;
  }
  .fc-scroller {
    .fc-list-table {
      .fc-widget-header {
        display: flex;
        justify-content: space-between;
      }
      thead {
        display: none;
      }
      td {
        padding: 4px;
      }
      .fc-list-day {
        display: none;
      }
      .fc-list-event {
        display: flex;
        width: 100%;
        margin-bottom: 4px;

        flex-direction: column;

        .fc-title-block {
          display: flex;
          align-items: center;

          .fc-color {
            height: 15px;
            min-width: 15px;
          }
          .fc-title {
            margin-left: 13px;
          }
        }
        .fc-time {
          margin-left: 28px;
          font-size: 0.8em;
        }
        &:hover {
          cursor: pointer;
          background: unset;
          color: var(--primary-color);
        }
      }
    }
    &::-webkit-scrollbar {
      width: 0;
    }
  }
  td.fc-day-today {
    background: transparent !important;
    .fc-daygrid-day-number {
      position: relative;
      color: var(--white);
      &:after {
        content: '';
        z-index: -1;
        position: absolute;
        top: 50%;
        left: 44%;
        transform: translate(-50%, -50%);
        background-color: var(--primary-color);
        border-radius: 50%;
        width: 23px;
        height: 23px;
      }
    }
  }
  tr td.fc-day-past {
    background: var(--placeholder-background-color);
  }

  .fc-dayGrid .fc-week-number,
  .fc-dayGrid .fc-day-number {
    padding: 4px 8px;
  }

  .fc-dayGridMonth-view {
    .fc-widget-content,
    .fc-scrollgrid {
      border: none;
    }
    .fc-scrollgrid-section td,
    th {
      border-right: none;
      border-bottom: none;
    }
  }
  .fc-timeGridWeek-view,
  .fc-timeGridDay-view {
    .fc-scrollgrid-section td,
    th {
      border-right: none;
      border-bottom: none;
    }
    .fc-timegrid-divider {
      display: none;
    }
    table {
      border: none;
      tr {
        td:nth-of-type(2) {
          border: none;
        }
      }
    }
    .fc-timegrid-axis {
      border: none;
    }
    .fc-widget-content {
      border-color: var(--calendar-border);
      &:first-of-type {
        border: none;
      }
      border-left: none;
    }
    .fc-timegrid-slots {
      .fc-timegrid-slot-label {
        border-top: none !important;
      }
      .fc-timegrid-slot {
        border-left: none;
        text-transform: uppercase;
        height: 2em;
        letter-spacing: 1px;
      }
      .fc-timegrid-slot-lane {
        border-top: 1px solid var(--calendar-border);
      }
      .fc-timegrid-slot-minor {
        border-top: 1px dashed var(--calendar-border);
      }
    }
  }

  .fc-event {
    border-color: var(--font-color);
  }
}

.fc-toolbar-chunk:nth-of-type(1) {
  .fc-button-group {
    button {
      background-color: unset;
      border: none;
      color: var(--font-color);
      &:hover,
      &:focus,
      &:active {
        background-color: unset;
      }
    }
  }
  .fc-button-primary {
    transition: 0.1s;
    color: var(--font-color);
    border-radius: 4px;
    background-color: var(--placeholder-background-color);
    font-weight: 500;
    border: none;
    opacity: 1;
    &:hover,
    &:focus,
    &:active,
    &:visited {
      border: none;
    }
    &.fc-today-button {
      cursor: pointer;
      color: var(--font-color);
      border-radius: 4px !important;
      background-color: var(--placeholder-background-color);
      font-weight: 500;
      min-width: 100px;
      border: 2px solid transparent;
      opacity: 1;
      &:hover,
      &:focus,
      &:active,
      &:visited {
        opacity: 0.7;
        border: 2px solid var(--placeholder-background-color);
        color: var(--font-color);
      }
    }
  }
}

.fc-button-primary:not(:disabled):hover {
  color: var(--primary-color);
}

.fc-button-primary:not(:disabled):focus {
  border: none;
}

.fc-button {
  .fc-icon {
    transition: 0.1s;
    color: var(--placeholder-background-color);
    &:hover {
      color: var(--font-color);
    }
  }
}

.fc-toolbar-chunk:nth-of-type(3) {
  .fc-button-group {
    .fc-button-primary {
      height: 40px;
      min-width: 80px;
      transition: 0.1s;
      color: var(--font-color);
      background-color: var(--placeholder-background-color);
      font-weight: 500;
      border: 2px solid transparent;
      opacity: 0.5;
      &:hover,
      &:focus,
      &:active {
        opacity: 0.7;
        background-color: unset;
        border: 2px solid var(--placeholder-background-color);
      }
      &.fc-button-active {
        opacity: 1;
        background-color: unset;
        color: var(--font-color);
      }
    }
  }
}

.fc-scroller-harness {
  border: none;
  .fc-col-header-cell.fc-day {
    border: none;
    text-align: center;
    font-weight: 400;
    padding-bottom: 15px;
    opacity: 0.7;
    color: var(--font-color);
    font-size: 12px;
    text-transform: uppercase;
  }
}

.fc-daygrid-body {
  .fc-scrollgrid-sync-table tbody {
    color: var(--font-color) !important;
    tr {
      border-bottom: 1px solid var(--calendar-border) !important;
      border-right: 1px solid var(--calendar-border) !important;
      .fc-daygrid-day {
        height: 113px;
        &.fc-future {
          background-color: var(--block-background-color) !important;
        }
      }
      &:last-of-type {
        table {
          border-bottom: none;
        }
      }
      &:first-of-type {
        td {
          border-top: none;
        }
      }
      td:first-child {
        border-left: none;
      }
    }
  }
}

.fc-dayGridWeek-view {
  table,
  th,
  td {
    border: none !important;
  }
  .fc-daygrid-day {
    border-top: 1px solid var(--calendar-border) !important;
    border-right: 1px solid var(--calendar-border) !important;
    border-bottom: 1px solid var(--calendar-border) !important;
    &:first-of-type {
      border-left: 1px solid var(--calendar-border) !important;
    }
  }
}

.fc-daygrid-event,
.fc-timegrid-event {
  &.fc-event {
    border-radius: 4px;
    border: none;
  }
  .fc-event-main-frame {
    line-height: 1.25;
    padding: 1px;
    font-weight: 500;
    color: var(--white);
    display: flex;
    justify-content: flex-start;
    flex-direction: column-reverse;
    .fc-event-time {
      width: 100%;
      margin-right: 10px;
      font-weight: 700;
    }
    .fc-event-title-container {
      width: 100%;
    }
    .fc-title {
      font-size: 1em;
      overflow: hidden;
    }
  }
}

.fc-axis {
  &.fc-day {
    border: none;
  }
}

.fc-widget-content {
  hr {
    &.fc-divider {
      border: none;
      border-bottom: 1px dashed var(--calendar-border);
      background-color: transparent;
      padding: 0 0 0;
    }
  }
}

.listViewWrapper {
  .columns {
    margin: 0;
  }
  .fc-view-harness {
    padding: 0;
  }
  .fc .fc-toolbar.fc-header-toolbar {
    margin-bottom: 0;
  }

  &.listViewPastDueWrapper {
    .fc-list-table tbody {
      display: flex;
      flex-direction: column-reverse;
    }
  }
}
