@use '_variables' as *;

.billing-page {
  a:focus-visible,
  button:focus-visible,
  div:focus-visible {
    outline: 0.2rem solid $mad-green;
    border: none;
  }

  font-family: $font-family;
  background-color: #fff;
  color: #000;

  & h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  a {
    color: #000;
  }

  .billing-container {
    padding: 0 16.4rem;
    margin: 0 auto;
    max-width: 190.2rem;
    width: 100%;
  }

  .empty-credit-card {
    height: 2.511rem;
    width: 4rem;
    border: 0.1rem solid #cfcfcf;
    border-radius: 0.4rem;
  }

  .underline {
    text-decoration: underline;
  }
}

//===========================================================//

/* Medium Desktops / Large Laptops (1680px and below) */
@media screen and (max-width: 1680px) {
  .billing-page {
    // background-color: rgb(173, 173, 250);
    .billing-container {
      padding: 0 19.4rem;
    }
  }
}

/* Small Desktops / Laptops (1440px and below) */
@media screen and (max-width: 1440px) {
  .billing-page {
    // background-color: rgb(127, 213, 224);
    .billing-container {
      padding: 0 10.4rem;
    }
  }
}

/* Laptops (1280px and below) */
@media screen and (max-width: 1280px) {
  .billing-page {
    // background-color: rgb(233, 161, 95);
    .billing-container {
      padding: 0 9.2rem;
    }
  }
}

/* Tablets (Landscape, 1024px and below) */
@media screen and (max-width: 1024px) {
  .billing-page {
    // background-color: rgb(208, 233, 95);
    .billing-container {
      padding: 0 6.4rem;
    }
  }
}

/* Tablets (Portrait, 768px and below) */
@media screen and (max-width: 768px) {
  .billing-page {
    // background-color: rgb(235, 128, 212);
    .billing-container {
      padding: 0 5.2rem;
    }
  }
}

/* Large Smartphones (Landscape, 640px and below) */
@media screen and (max-width: 640px) {
  .billing-page {
    // background-color: rgb(186, 204, 252);
    .billing-container {
      padding: 0 4.4rem;
    }
  }
}

/* Medium Smartphones (480px and below) */
@media screen and (max-width: 480px) {
  .billing-page {
    // background-color: rgb(163, 231, 243);
    .billing-container {
      padding: 0 2rem;
    }
  }
}

/* Small Smartphones (360px and below) */
@media screen and (max-width: 360px) {
  .billing-page {
    // background-color: rgb(243, 163, 170);
    .billing-container {
      padding: 0 0.8rem;
    }
  }
}
