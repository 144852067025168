.tox {
  font-family: var(--font-family) !important;
  border: none !important;

  .tox-custom-editor:focus-within,
  .tox-listboxfield .tox-listbox--select:focus,
  .tox-textarea-wrap:focus-within,
  .tox-textarea:focus,
  .tox-textfield:focus {
    outline: none !important;
  }

  .tox-custom-editor:focus-within,
  .tox-listboxfield .tox-listbox--select:focus,
  .tox-textarea-wrap:focus-within,
  .tox-textarea:focus,
  .tox-textfield:focus {
    border-color: var(--primary-color) !important;
  }

  .tox-toolbar__group {
    padding: 0 !important;
    border: 0 !important;
  }

  .tox-tbtn--select {
    width: auto !important;
  }

  .tox-tbtn--bespoke .tox-tbtn__select-label {
    width: fit-content !important;
  }

  font-family: var(--font-family) !important;
  font-weight: 500 !important;

  // .tox-edit-area__iframe {
  //   background-color: var(--input-background-color) !important;
  //   color: var(--font-color) !important;
  // }

  .tox-button {
    font-family: var(--font-family) !important;
    font-weight: 500 !important;
  }

  .tox-dialog {
    .tox-dialog {
      &__body-nav-item {
        border-bottom: none;
        background-color: transparent !important;

        &--active,
        &:hover {
          border-color: var(--primary-color);
          color: var(--primary-color);
          text-decoration: none;
        }
      }
    }

    &__footer {
      .tox-button {
        background-color: var(--primary-color) !important;
        border-color: var(--primary-color) !important;

        &:hover {
          opacity: 0.7;
        }
      }

      .tox-button--secondary {
        background-color: var(--secondary-btn-background-color) !important;
        border-color: var(--secondary-btn-background-color) !important;
      }
    }
  }
}

.background-dark {
  .tox {
    .tox-tbtn,
    .tox-toolbar__group,
    .tox-editor-header,
    .tox-toolbar__primary:not(:root) {
      background-color: var(--dark-theme-lighten-50);
      background-position: initial !important;
      cursor: pointer;
    }
    .tox-toolbar__primary:not(:root) {
      background: var(--dark-theme-lighten-50) !important;
    }
    .tox-tbtn__select-label {
      cursor: pointer;
    }
    .tox-toolbar__overflow {
      background-color: var(--dark-theme-lighten-50) !important;
      border-color: var(--dark-theme-lighten-50) !important;
    }
    .tox-button--secondary {
      background-color: var(--secondary-btn-background-color) !important;
      border-color: var(--dark-theme-lighten-60) !important;

      &:hover {
        opacity: 0.7 !important;
      }
    }
    .tox-dropzone {
      background-color: var(--dark-theme-lighten-50);
    }
    .tox-dialog-wrap__backdrop {
      background-color: var(--modal-background);
    }

    // #custom-signature_ifr {
    //   background: var(--dark-theme-lighten-80);
    // }

    // select start
    .tox-selected-menu,
    .tox-listbox--select {
      border-color: var(--dark-theme-lighten-50) !important;
    }
    .tox-listbox--select,
    .tox-collection__group,
    .tox-collection__item--enabled {
      background-color: var(--dark-theme-lighten-50) !important;
    }
    // select end

    // modal
    .tox-dialog {
      border-color: var(--dark-theme-lighten-60);
      background-color: var(--dark-theme-lighten-60);

      &__header,
      &__footer {
        background-color: var(--dark-theme-lighten-60);
      }
      .tox-dialog__footer {
        border-color: var(--dark-theme-lighten-50) !important;
      }
    }
  }
}
