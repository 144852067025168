.Circle {
  background: #ededed;
  border-radius: 100% !important;
  padding: 2.5px;
}

.Square {
  background: #ededed;
  border-radius: 5px !important;
  padding: 2.5px;
}