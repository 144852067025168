.wrapper {
  min-height: 100vh;
  font-size: var(--font-size);
}
.flex {
  display: flex;
}
.space-between {
  justify-content: space-between;
}
.flex-end {
  justify-content: flex-end;
}
.container-large-full {
  max-width: 100%;
  margin: 0 40px;
}
.container-medium-full {
  max-width: 90%;
  margin: 0 auto;
}

@media screen and (min-width: 1408px) {
  .container {
    &.custom-container,
    &.container-full {
      max-width: 1272px;
      padding: 0 15px;
    }
  }
}

@media screen and (max-width: 1407px) {
  .container {
    &.container-full {
      max-width: 100%;
      padding: 0 10px;
    }
  }
}
