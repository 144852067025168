.modal.custom-modal {
  z-index: 100;
  --bulma-modal-content-width: 640px;

  .modal-card-head {
    background-color: var(--block-background-color);
    width: calc(100% - 90px);
    padding: 25px 25px 40px 25px;
    border-radius: 0;
    border: none;
    box-shadow: none;

    .mbri-close:hover {
      cursor: pointer;
      color: var(--primary-color);
    }

    & + .modal-card-body {
      padding-top: 0;
    }
    .modal-card-title {
      margin: 0;
      color: var(--text-color);
    }
  }

  .modal-card-body {
    background-color: var(--block-background-color);
    padding: 50px 25px 25px 25px;
    width: calc(100% - 90px);
  }

  .report-modal-card-body {
    background-color: var(--block-background-color);
    padding: 50px 40px;
    width: 1250px;
  }

  .modal-card {
    justify-content: center;
    align-items: center;
  }

  .modal-background {
    background-color: var(--modal-background);
  }

  &.event-modal {
    .modal-card-body {
      border-radius: 4px;
      width: 635px;
      padding: 0;
      overflow-y: auto;
    }
  }

  &.securities {
    .modal-card {
      width: 600px;
      .modal-card-body{
        width: 600px;
      }
    }
    .modal-card-body {
      border-radius: 4px;
    }
  }

  &.leads-assignments-modal {
    .modal-card-head {
      width: 800px;
    }
    .modal-card {
      width: 800px;

      .modal-card-body {
        width: 800px;
      }
    }
  }

  &.event-detail-modal {
    .modal-card-body {
      overflow-y: scroll;
      border-radius: 0;
      padding: 0;
      width: 843px;
    }
    .modal-card-foot {
      width: 843px;
      height: 65px;
      border-top: 1px solid var(--navbar-border);
      background-color: var(--white);
      display: flex;
      justify-content: flex-end;
    }
    .modal-card-head {
      width: 843px;
      padding: 22px 32px;
      border-bottom: 1px solid var(--navbar-border);
      .modal-card-title {
        font-family: var(--font-family-headers);
        font-size: 18px;
      }
    }
    .modal-card {
      overflow: visible;
    }
  }

  &.notes-modal {
    z-index: 99;
    .modal-card-body {
      transition: 0.3s;
      padding: 16px 0;
      height: 444px;
      width: 582px;
    }
  }

  &.windowed {
    right: 0;
    bottom: 0;
    height: auto;
    width: 0;
    display: contents; // for older Safari versions
    .modal-background {
      display: none;
    }
    .modal-card {
      box-shadow: var(--box-shadow);
      width: auto;
      position: fixed;
      right: 0;
      bottom: 0;
      overflow: visible;
      z-index: 99;
      .modal-card-body {
        overflow: visible;
      }
    }
  }

  &.full-screen {
    display: inline-flex; // for older Safari versions
    .modal-card {
      width: 80vw;
      .modal-card-body {
        width: 80vw;
        height: auto;
      }
    }
    .modal-card-head {
      width: 80vw;
      padding: 22px 32px;
      border-bottom: 1px solid var(--navbar-border);
    }
  }

  &.hidden {
    .modal-card {
      .modal-card-body {
        position: fixed;
        border: 1px solid var(--contact-search-background-color);
        right: 0;
        bottom: 0;
        height: 60px;
        transition: 0.3s;
        overflow-y: hidden;
      }
    }
  }
}
.csg-modal {
  .modal-card {
    width: 1200px;
  }
  .modal-card-body {
    padding: 0 !important;
  }
}
.csg-modal-filters {
  .modal-card {
    width: 1200px;
  }
}
