.color-title {
  color: #091020;
}
.color-subtitle {
  color: #4e4e4e;
}
.background-dark {
  .color-title {
    color: #ffffff;
  }
  .color-subtitle {
    color: #ffffff;
  }
}
