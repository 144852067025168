.DialerInboundWrapper {
  color: black;
  .DialerInboundIconContainer {
    height: 150px;
    text-align: center;
    line-height: 150px;

    .DialerInboundIcon {
      margin: auto;
      i {
        background: rgb(237, 237, 237);
        width: 75px;
        height: 75px;
        margin: auto;
        border-radius: 100%;
        font-size: 25px;
        line-height: 75px;
      }
    }
  }

  .DialerInboundInfoContainer {
    height: 100px;
    margin-top: 20px;
    margin-bottom: 20px;
    .DialerInboundInfo {
      text-align: center;
      margin: auto;

      h2.DialerInboundInfoTitle {
        font-size: 24px;
      }

      p.DialerInboundInfoText {
        font-size: 20px;
      }

      p.DialerInboundInfoSubText {
        font-size: 16px;
      }
    }
  }

  .DialerInboundActions {
    display: flex;
    text-align: center;
    .DialerInboundActionItem {
      flex-grow: 1;

      .DialerInboundActionItemButton {
        width: 75px;
        height: 75px;
        border-radius: 100%;
        margin: auto;
        // background: #ededed;
        font-size: 35px;
        line-height: 75px;
        cursor: pointer;

        .Rotate135Deg {
          --fa-rotate-angle: 135deg;
        }

        i {
          font-size: 33px;
          color: white;
        }
      }

      .Accept {
        background: green;
      }

      .Reject {
        background: red;
      }
    }
  }

  .DialerInboundMatches {
    margin: 20px 0px;
    padding: 10px;
    // background: #f2f2f2;

    .DialerInboundMatchesTitle {
      h3 {
        margin-bottom: 10px;
        font-size: 18px;
      }
    }

    .DialerInboundMatchesRow {
      display: flex;
      flex-direction: row;

      .DialerInboundMatchesRowInfo {
        .DialerInboundMatchesRowName {
          font-size: 15px;
          text-align: left;
        }
        .DialerInboundMatchesRowAddress {
          font-size: 13px;
        }
      }

      .DialerInboundMatchesRowActions {
        text-align: right;
        flex-grow: 1;
        .DialerInboundMatchesRowAction {
          font-size: 20px;
        }
        .DialerInboundMatchesRowActionButton {
          border: 1px solid rgba(0, 128, 0, 0.601);
          border-radius: .5em;
          padding: 2.5px 10px;
          background-color: rgba(0, 128, 0, 0.601);
          color: white;
          cursor: pointer;
          margin: auto;
          align-self: center;
        }
      }
    }
  }
}
