.background-dark {
  .topnavbar {
    border-bottom: none;
    box-shadow: var(--box-shadow);
  }
}

header {
  position: fixed;
  width: 100%;
  //min-width: 1100px;
  z-index: 98;
}

//OVERLAY FOR MODAL

.overlay {
  width: 100%;
  height: 100vh;
  // opacity: 0.1;
  // background: var(--font-color);
  z-index: 99;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
