.filter-component-wrapper {
  background-color: var(--block-background-color);
  box-shadow: var(--box-shadow);
  margin-bottom: 24px;
  padding: 24px;
  .field {
    margin-bottom: 15px;
    &:last-of-type {
      margin-bottom: 0;
    }
    .control {
      .title {
        margin-bottom: 5px;
      }
      .custom-select {
        .custom-select__multi-value {
          &__label {
            border-radius: 4px;
            border-top-right-radius: unset;
            border-bottom-right-radius: unset;
          }
          &__remove {
            border-radius: 4px;
            border-top-left-radius: unset;
            border-bottom-left-radius: unset;
          }
        }
        &.with-include {
          .custom-select__menu {
            width: 250px;
          }
        }
      }
    }
  }
  .buttons-wrapper {
    display: flex;
    justify-content: flex-start;
    button {
      margin-right: 15px;
      width: 95px;
    }
  }
}
