.DialerActiveWrapper {

  color: black;

  .DialerActiveIconContainer {
    height: 150px;
    text-align: center;
    line-height: 150px;

    .DialerActiveIcon {
      margin: auto;
      i {
        background: rgb(237, 237, 237);
        width: 75px;
        height: 75px;
        margin: auto;
        border-radius: 100%;
        font-size: 25px;
        line-height: 75px;
      }
    }
  }

  .DialerActiveInfoContainer {
    height: 100px;
    .DialerActiveInfo {
      text-align: center;
      margin: auto;

      h2.DialerActiveInfoNumber {
        font-size: 24px;
        color: #060606 !important;
      }

      p.DialerActiveInfoDuration {
        font-size: 16px;
        color: #060606 !important;
      }
    }
  }
  .DialerActiveActionContainer {
    height: 100%;
    .DialerActiveAction {
      display: flex;
      text-align: center;
      .DialerActiveActionItem {
        flex-grow: 1;

        // Legacy
        // .DialerActiveActionItemButton {
        // 	width: 50px;
        // 	height: 50px;
        // 	border-radius: 100%;
        // 	margin: auto;
        // 	background: #ededed;
        // 	font-size: 25px;
        // 	line-height: 50px;
        // 	cursor: pointer;

        // 	.Active {
        // 		color: red !important;
        // 	}
        // 	.Rotate135Deg {
        // 		--fa-rotate-angle: 135deg;
        // 	}
        // }

        // New
        .DialerActiveActionItemButton {
          width: 50px;
          height: 50px;
          margin: auto;
          font-size: 25px;
          line-height: 50px;
          cursor: pointer;

          .Active {
            color: red !important;
          }
          .Rotate135Deg {
            --fa-rotate-angle: 135deg;
          }
        }

        .DialerActiveActionItemLabel {
          margin-top: 10px;
          text-align: center;
          color:rgb(56, 56, 56);
          font-size: 12px;
        }
      }
    }
  }
  .DialerActiveActionsTagContainer {
    .DialerActiveActionsTag {
    }
  }
}
