.skeleton {
  background: var(--light-gray);
  &.text {
    width: 100%;
    height: 15px;
  }
  &.paragraph {
    width: 100px;
    height: 15px;
    &.big {
      width: 215px;
    }
  }
  &.icon {
    height: 50px;
    width: 50px;
    border-radius: 6px;
  }
  &.title {
    max-width: 25%;
    height: 20px;
  }
  &.avatar {
    width: 50px;
    height: 50px;
    border-radius: 50%;
  }
  &.small-avatar {
    width: 30px;
    height: 30px;
    border-radius: 50%;
  }
  &.thumbnail {
    width: 150px;
    height: 34px;
    &.super-small {
      width: 15px;
      height: 15px;
    }
    &.small {
      width: 30px;
      height: 30px;
    }
    &.long {
      height: 30px;
      width: 320px;
    }
    &.medium {
      height: 30px;
      width: 161px;
    }
  }
  &.margin-bottom {
    margin-bottom: 5px;
  }
  &.margin-left {
    margin-left: 10px;
  }
}

// themes

.skeleton-wrapper {
  position: relative;
  overflow: hidden;
  padding: 10px 15px;
  margin-bottom: 20px;
  &.background-default {
    background: var(--light);
  }
  &.background-dark {
    background: var(--dark-theme-lighten-50);
    .skeleton {
      background: var(--dark-theme-lighten-40);
    }
    .shimmer {
      background: var(--dark-theme-shimmer-color);
    }
  }
  &:last-of-type {
    margin-bottom: 0;
  }
  &.no-margin {
    margin: 0;
  }
  &.card {
    border-radius: 6px;
  }
  &.fit-content {
    width: fit-content;
  }
  &.fluid {
    width: 100%;
  }
}

// animation effects

.shimmer-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  animation: loading 2s infinite;
  .shimmer {
    width: 50%;
    height: 100%;
    background: var(--shimmer-color);
    transform: skewX(-20deg);
    box-shadow: var(--shimmer-shadow);
  }
}

@keyframes loading {
  0% {
    transform: translateX(-150%);
  }
  50% {
    transform: translateX(-60%);
  }
  100% {
    transform: translateX(150%);
  }
}
