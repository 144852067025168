// THEMES
.font-size-small {
  --font-size: 1.4rem;
  --error-font-size: 10px;
  h1 {
    font-size: 5.5rem;
  }
  h2 {
    font-size: 5.3rem;
  }
  h3 {
    font-size: 4.1rem;
  }
  h4 {
    font-size: 2.8rem;
  }
  h5 {
    font-size: 1.8rem;
  }
  h6 {
    font-size: 1.5rem;
  }
  p {
    font-size: var(--font-size);
  }
}
.font-size-default {
  --font-size: 1.5rem;
  --error-font-size: 12px;

  h1 {
    font-size: 6rem;
  }
  h2 {
    font-size: 5.8rem;
  }
  h3 {
    font-size: 4.6rem;
  }
  h4 {
    font-size: 3.3rem;
  }
  h5 {
    font-size: 2.3rem;
  }
  h6 {
    font-size: 2rem;
  }
  p {
    font-size: var(--font-size);
  }
}
.font-size-large {
  --font-size: 1.6rem;
  --error-font-size: 14px;

  h1 {
    font-size: 6.5rem;
  }
  h2 {
    font-size: 6.3rem;
  }
  h3 {
    font-size: 5.1rem;
  }
  h4 {
    font-size: 3.8rem;
  }
  h5 {
    font-size: 2.8rem;
  }
  h6 {
    font-size: 2.5rem;
  }
  p {
    font-size: var(--font-size);
  }
}

.primary-color-orange {
  --primary-color: var(--orange);
  --primary-color-darken-20: var(--orange-darken-20);
  --primary-color-darken-40: var(--orange-darken-40);
}
.primary-color-green {
  --primary-color: var(--green);
  --primary-color-darken-20: var(--green-darken-20);
  --primary-color-darken-40: var(--green-darken-40);
}
.primary-color-pink {
  --primary-color: var(--pink);
  --primary-color-darken-20: var(--pink-darken-20);
  --primary-color-darken-40: var(--pink-darken-40);
}
.primary-color-yellow {
  --primary-color: var(--yellow);
  --primary-color-darken-20: var(--yellow-darken-20);
  --primary-color-darken-40: var(--yellow-darken-40);
}
.primary-color-purple {
  --primary-color: var(--purple);
  --primary-color-darken-20: var(--purple-darken-20);
  --primary-color-darken-40: var(--purple-darken-40);
}
.background-default {
  a {
    color: var(--black);
  }
}
.background-dark {
  color: var(--white);
  --root-background-color: var(--dark-theme-lighten-80);
  --layout-background-color: var(--dark-theme-lighten-60);
  --block-background-color: var(--dark-theme-lighten-60);
  --gray-bg-color: var(--dark-theme-lighten-60);
  --font-color: var(--white);
  --navbar-item-background-hover: var(--placeholder-background-color);
  --placeholder-background-color: var(--dark-theme-lighten-50);
  --darker-placeholder-background-color: var(--dark-theme-lighten-70);
  --contact-search-background-color: var(--transparent-background);
  --gray: var(--dark-gray);
  --notification-error: var(--notification-error-transparent);
  --notification-success: var(--notification-success-transparent);
  --search-background: var(--dark-theme-lighten-50);
  --secondary-btn-color: var(--white);
  --secondary-btn-border-color: var(--dark-theme-lighten-50);
  --secondary-btn-background-color: var(--dark-theme-lighten-50);
  --secondary-btn-background-color-hover: var(--dark-theme-lighten-60);
  --contact-small-title-color: var(--white);
  --dropdown-hover-background: var(--dark-theme-lighten-50);
  --input-border-color: var(--dark-theme-lighten-50);
  --input-background-color: var(--dark-theme-lighten-50);
  --navbar-border: var(--dark-theme-lighten-60);
  --select-multi-value-color: var(--dark-theme-lighten-40);
  --table-color: var(--white);
  --table-header-background-color: var(--table-header-background-color-dark);
  --table-border: var(--table-border-dark);
  --tr-even-background: var(--table-header-background-color-dark);
  --tr-odd-background: #242526;
  --table-head-border: var(--table-border);
  --table-item-background-color: var(--dark-theme-lighten-50);
  --table-item-striped-background-color: var(--dark-theme-lighten-40);
  --light-gray: var(--dark-gray-20);
  --dashboard-no-content-text: var(--gray);
  --autofill-color: #38383a;
  --icons-background: #585960;
  --navigation-buttons-color: var(--white);
  --modal-background: rgba(100, 100, 100, 0.6);
  --fc-page-text-color: var(--white);
  --fc-page-bg-color: var(--root-background-color);
  --fc-list-event-hover-bg-color: var(--dark-theme-lighten-40);
  --signup-input-background-color: var(--signup-input-background-color-dark-theme);
  --signup-input-text-color: #fff;
  --signup-background-color: var(--dark-theme-lighten-40);
  --signup-background-color: initial;
  --lead-assignments-badge-color: #fff;


  --sms-action-btn-color: var(--white);
  --sms-action-btn-background-color: var(--dark-theme-lighten-50) !important;
  --sms-action-btn-border-color: var(--dark-theme-lighten-50);

  --sms-message-bubble-color: white;
  --sms-message-background-color: #38383a;
  --sms-message-border-color: #38383a;

  --sms-audio-color: white;
  --sms-audio-bg: black;


  --sms-main-bg: #242526;
  --sms-main-bg-white: #242526;
  --sms-main-outline: var(--primary-color);
  --sms-main-color: #f2f2f2;

  --dialer-main-btn-bg: #959595;
  --dialer-main-btn-color: white;

  // DialerWrapper
  --dialer-main-wrapper-border: #dedede00;
  // DialerPadDisplay
  --dialer-main-numbers-bg: #242526d4;

  // SubscriptionsCardSmHeader
  --sms-subscriptions-header-color: #b7b7b7;
  // SubscriptionsCardSmText
  --sms-subscriptions-text-color: white;
  // SubscriptionsCardSm
  --sms-subscriptions-text-bg: #242526;
  --sms-subscriptions-text-border: #242526;

  --sms-subscriptions-color: #808080;
  --sms-subscriptions-active-color: white;

  --sms-table-row-cell-color: white;
  --sms-table-row-cell-bg: #242526;

  --sms-conversations-list-color: black;
  --sms-conversations-list-bg: #242526;
  --sms-conversations-preview-active: #38383a;
  --sms-conversations-preview-active-color: white;

  --sms-allocation-grey: #242526;
  --sms-allocation-dark-grey: #38383a;
  --sms-allocation-white: #38383a;

  a {
    color: var(--white);
  }
}

h2 {
  line-height: 1.81;
  letter-spacing: -0.5px;
}

h3 {
  line-height: 1.54;
}

h4 {
  font-size: 33px;
  letter-spacing: 0.25px;
}

h5 {
  line-height: 1.35;
}

h6 {
  font-weight: 500;
  line-height: 1.55;
  letter-spacing: 0.15px;
}

p {
  line-height: 1.5;
  letter-spacing: 0.25px;
  &.small-title {
    font-size: calc(100% - 3px);
  }
}
