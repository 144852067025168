.DialerHistoryWrapper {
  color: black;
  height: 100%;

  .DialerHistoryTitle {
    font-size: 24px;
    padding: 10px;
    color: #060606 !important;
  }

  .DialerHistoryItems {
    height: 100%;
    overflow: scroll;
    max-height: 475px;
  }

  .DialerHistoryItem {
    display: flex;
    flex-direction: row;
    padding: 10px;
    margin-left: 5px;
    margin-right: 5px;
    border-bottom: 1px solid #e8e8e8;
    cursor: pointer;

    .DialerHistoryItemNumber {
      flex-grow: 1;
      text-align: left;

      .DialerHistoryItemNumberText {
        font-size: 16px;
        font-weight: 400;
        color: #1b1b1b;
      }
    }

    .DialerHistoryItemDate {
      flex-grow: 1;
      text-align: right;

      .DialerHistoryItemDateText{
        font-size: 12px;
        color: #a3a3a3;
      }
    }

    .DialerHistoryItemType {
      flex-grow: 1;
      text-align: right;

      .DialerHistoryItemTypeIco{
      }
    }
  }
}
