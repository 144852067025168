.main-actions {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 45px;
  .buttons-wrapper {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    button {
      margin-left: 16px;
    }
    .firelight {
      width: 190px;
    }
    .mini_calendar {
      width: 150px;
    }
  }
}

.filter {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  .main-filters {
    width: 33%;
    display: flex;
    justify-content: space-between;
    padding: 0 1.8rem;
    border-bottom: 2px solid var(--navbar-border);
    p {
      font-weight: 500;
      text-transform: uppercase;
      transition: 0.1s;
      cursor: pointer;
      position: relative;
      &:hover {
        opacity: 0.7;
      }
      &:before {
        position: absolute;
        content: '';
        transition: 0.2s;
        width: 0;
        height: 2px;
        bottom: -2px;
        left: 50%;
        transform: translateX(-50%);
        background-color: var(--primary-color);
      }
      &.active {
        font-weight: 500;
        &:before {
          min-width: 62px;
          width: 110%;
        }
        &:last-child {
          &:before {
           min-width: 123px;
          }
        }
      }
    }
  }
  .filter-actions {
    display: flex;
    justify-content: flex-end;
    width: 62%;
    .search-wrapper {
      width: 47%;
      margin-right: 25px;
      position: relative;
      .help {
        position: absolute;
        bottom: -22px;
      }
    }
    .other-actions {
      display: flex;
    }
    button {
      width: 84px;
      margin-right: 25px;
    }
    .advanced-search {
      height: 100%;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      border: 1px solid var(--secondary-btn-background-color);
      background-color: var(--secondary-btn-background-color);
      border-right-color: var(--light-gray);
      width: 44px;
      transition: 0.1s;
      svg {
        fill: var(--font-color);
      }
      &:hover {
        border-color: var(--primary-color);
      }
      &.is-active {
        background-color: var(--primary-color);
        border-color: var(--primary-color);
        svg {
          fill: var(--white);
        }
      }
    }
    .contact-filter {
      position: relative;
      cursor: pointer;
      border: 1px solid var(--secondary-btn-background-color);
      background-color: var(--secondary-btn-background-color);
      border-right-color: var(--light-gray);
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      width: 44px;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
      transition: 0.1s;
      svg {
        fill: var(--font-color);
      }
      &:hover {
        border-color: var(--primary-color);
      }
      &.is-active {
        background-color: var(--primary-color);
        border-color: var(--primary-color);
        svg {
          fill: var(--white);
        }
      }
    }
    .custom-select {
      width: 152px;

      &.min-width-200 {
        width: 200px;
      }
      border-right: 1px solid var(--light-gray);
      .custom-select__control {
        background-color: var(--secondary-btn-background-color) !important;
        border-color: var(--secondary-btn-background-color) !important;
        border-radius: unset;
        cursor: pointer;
        &:hover {
          border-color: var(--primary-color) !important;
        }
        input:focus {
          background: transparent !important;
        }
      }
      &.with-contacts {
        .custom-select__control {
          border-radius: unset;
        }
      }
      &.sorting-select-rounded {
        .custom-select__control {
          border-top-left-radius: 4px;
          border-bottom-left-radius: 4px;
          outline: none;
        }
        .custom-select__input {
          input {
            border: none !important;
          }
        }
      }
      &.sorting-select-rounded-right {
        border: none;
        .custom-select__control {
          border-top-right-radius: 4px;
          border-bottom-right-radius: 4px;
        }
      }
      &.sorting-select-rounded-all {
        border: none;
        .custom-select__control {
          border-radius: 4px;
        }
      }
    }
    .order-button {
      cursor: pointer;
      width: 44px;
      background-color: var(--search-background);
      border: 1px solid var(--search-background);
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      transition: 0.1s;
      &:hover {
        border-color: var(--primary-color);
      }
      i {
        transition: 0.1s;
      }
      &.flipped {
        i {
          transform: rotate(180deg);
        }
      }
    }
  }
  &.management-filter {
    .filter-actions {
      width: 100%;
      .search-wrapper {
        width: 83%;
      }
    }
  }
}

.new-contact {
  position: relative;

  &--content {
    position: absolute;
    display: flex;
    flex-direction: column;
    row-gap: 4px;
    z-index: 99;
    border: 1px solid var(--secondary-btn-background-color);
    background-color: var(--gray-bg-color);
    width: 150px;
    left: -35px;
    bottom: -81px;
    border-radius: 4px;
    a {
      display: block;
      padding: 5px;
      background-color: transparent;
    }

    a:hover {
      opacity: 0.7;
    }
  }
}

@media only screen and (max-width: (1024px - 1px)) {
  .filter {
    flex-direction: column;
    .main-filters {
      margin-bottom: 24px;
      width: 50%;
      height: 35px;
    }
    .filter-actions {
      width: 100%;
      .search-wrapper {
        width: 67%;
      }
    }
  }
}
