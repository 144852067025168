.DialerPadWrapper {
  color: black;
  .DialerPadDisplay {
    height: 100px;
    margin: auto;
    line-height: 100px;
    font-size: 34px;
    text-align: center;
  }

  .DialerPadDigits {
    padding: 0 25px;

    .DialerPadDigitRow {
      display: flex;

      .DialerPadDigit {
        flex-grow: 3;
        margin: auto;
        text-align: center;
        font-size: 24px;
        line-height: 75px;
        height: 75px;
        cursor: pointer;
      }
    }
  }

  .DialerPadActions {
    padding: 0 25px;
    margin-top: 40px;

    .DialerPadActionRow {
      display: flex;

      .DialerPadAction {
        flex-grow: 3;
        margin: auto;
        text-align: center;
        font-size: 24px;
        line-height: 75px;
        height: 75px;
      }

      .DialerPadAction.Call {
        background: green;
        border-radius: 57%;
        line-height: 81px;
        width: 0px;
        height: 72px;

        i {
          font-size: 33px;
          color: white;
        }
      }
    }
  }
}
