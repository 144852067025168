.DialerNavWrapper {
  bottom: 0;
  left: 0;
  position: absolute;
  width: 100%;
  height: 40px;
  text-align: center;
  line-height: 40px;
  color: black;

  .DialerNavButtons {
    display: flex;
    flex-direction: row;

    .DialerNavButton {
      flex-grow: 1;
      margin: auto;
      cursor: pointer;
      font-size: 16px;
      font-weight: 400;
      color: #6b6b6b;
    }

    .DialerNavButton.Active {
      color: #444444;
      font-weight: 600;
    }
  }
}
