.button {
  font-size: var(--bulma-control-size);
  font-weight: 500;
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 155px;
  p {
    font-size: var(--bulma-control-size) !important;
    font-weight: 500;
    margin-bottom: 0;
  }

  --bulma-control-radius: 4px !important;

  &.is-auto {
    width: auto;
  }
  &.is-warning {
    color: var(--white);

    p {
      color: var(--white);
    }
  }
  &.is-danger {
    color: var(--white);

    p {
      color: var(--white);
    }
  }
  &.is-success {
    color: var(--white);
    p {
      color: var(--white);
    }
  }
  &.is-primary {
    color: var(--white);
    p {
      color: var(--white);
    }
    i {
      font-size: 12px;
    }
    background-color: var(--primary-color);
    border-color: var(--primary-color);
    &.is-success {
      background-color: var(--success);
      border-color: var(--success);
    }
    &:active,
    &:hover {
      background-color: var(--primary-color-darken-20);

      &.is-success {
        background-color: var(--success-hover);
        border-color: var(--success-hover);
      }
    }
  }
  &.is-primary[disabled] {
    background-color: var(--primary-color);
    border-color: var(--primary-color);
    &.is-success {
      background-color: var(--success-hover);
      border-color: var(--success-hover);
    }
  }
  &.is-secondary {
    color: var(--font-color);
    transition: 0.1s;
    background-color: var(--secondary-btn-background-color);
    border-color: var(--secondary-btn-border-color) !important;
    p {
      line-height: 20px;
      color: var(--secondary-btn-color);
    }
    &:active,
    &:hover {
      background-color: var(--secondary-btn-background-color-hover);
    }
  }
  &.is-default {
    background-color: var(--layout-background-color);
    color: var(--font-color);
  }
  &.is-fullwidth {
    justify-content: center;
  }
  &:focus {
    box-shadow: none !important;
  }
  &[disabled] {
    box-shadow: none !important;
  }
}
