.status-change,
.source-change,
.color-change {
  position: relative;
  .status-change-modal {
    z-index: 1;
    width: 245px;
    height: auto;
    position: absolute;
    top: 30px;
    left: 0;
    padding: 5px 2px 5px 2px;
    background-color: var(--layout-background-color);
    box-shadow: var(--box-shadow);
    button {
      margin-top: 5px;
    }
    .custom-select {
      &__menu {
        max-height: 300px;
        overflow-y: hidden;
      }
      &__group-heading {
        .menu-header {
          p.description {
            color: var(--gray);
            font-size: 12px;
            &:hover {
              color: var(--gray);
              cursor: unset;
            }
          }
        }
      }
    }
    .colors-wrapper {
      padding: 0 12px;
      margin: 5px 5px;
      border: 1px solid var(--search-background);
      border-radius: 4px;
      .menu-header {
        .description {
          color: var(--gray);
          font-size: 12px;
          margin-top: 5px;
          margin-bottom: 10px;
        }
      }
      .colors-options {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
        .color {
          cursor: pointer;
          transition: 0.1s;
          box-shadow: var(--box-shadow);
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
          width: 20px;
          height: 20px;
          &:hover {
            opacity: 0.7;
          }
          span {
            display: none;
            color: var(--white);
          }
          &.active {
            span {
              display: block;
            }
          }
        }
      }
      button {
        width: auto;
        margin: 12px 0;
        margin-top: 18px;
      }
    }
  }
}

@media only screen and (max-width: 1440px) {
  .color-change {
    .status-change-modal {
      left: -100px;
    }
  }
}
