.notification-container {
  .notification-wrapper {
    cursor: pointer;
    display: block;
    position: fixed;
    padding: 15px 25px;
    top: -400px;
    width: 900px;
    // 10000 needed for modal in modal (attachemnt uploading error)
    z-index: 10000;
    left: 50%;
    transform: translateX(-50%);
    animation: moveOpen 2s;
    animation-timing-function: ease;
    animation-fill-mode: forwards;
    transition: 0.1s;
    &:hover {
      box-shadow: var(--box-shadow);
      animation-play-state: paused;
    }
    &.error {
      background-color: var(--notification-error);
    }
    &.success {
      background-color: var(--notification-success);
    }
    .heading {
      text-transform: capitalize;
    }
    p {
      color: var(--font-color);
      &.heading {
        font-weight: 600;
      }
    }
    ul {
      padding: 0;
      li {
        margin-left: 20px;
        list-style: circle;
      }
    }
  }
  &.login {
    p,
    li {
      font-size: 15px;
    }
    max-width: 900px;
  }
}

@media only screen and (max-width: (1440px - 1px)) {
  .notification-container {
    max-width: 965px;
  }
}

@media only screen and (max-width: 1024px) {
  .notification-container {
    max-width: 810px;
    .notification-wrapper {
      width: 600px;
    }
  }
}

@media only screen and (max-width: 480px) {
  .notification-container {
    max-width: 100%;
    .notification-wrapper {
      width: 100%;
    }
  }
}

@keyframes moveOpen {
  from {
    -webkit-transform: translate(-50%, -400px);
  }
  10% {
    -webkit-transform: translate(-50%, 490px);
  }
  12% {
    -webkit-transform: translate(-50%, 492px);
  }
  16% {
    -webkit-transform: translate(-50%, 490px);
  }
  80% {
    -webkit-transform: translate(-50%, 490px);
  }
  85% {
    -webkit-transform: translate(-50%, 495px);
  }
  to {
    -webkit-transform: translate(-50%, -400px);
  }
}
