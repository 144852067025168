.tabs {
  li.is-active a {
    border-bottom-color: var(--primary-color);
    color: var(--primary-color);
  }

  a:hover {
    color: var(--primary-color) !important;
  }
}
