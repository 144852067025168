// Styles for DatePicker pop-up

.containerDatePickerW100 > .react-datepicker-wrapper {
  width: 100% !important;
}
.react-datepicker-popper {
  z-index: 55;

  .react-datepicker {
    font-size: 14px;
    color: var(--font-color);
    font-family: 'Roboto';
    background-color: var(--block-background-color);

    &__time-container,
    &__time-box {
      width: 95px !important;
    }
    &__time {
      background: var(--block-background-color);
    }
    &__time-list {
      overflow-x: hidden !important;
    }
    &__time-list-item {
      transition: 0.1s;
      background-color: var(--block-background-color);
      &:hover {
        background-color: var(--navbar-item-background-hover) !important;
      }
    }
    &__time-list-item--selected {
      background-color: var(--navbar-item-background-hover) !important;
      .fa-chevron-down {
        color: red;
      }
    }
    &__header {
      color: var(--font-color);
      background-color: var(--navbar-item-background-hover);
    }
    &__current-month,
    &__day-name,
    &-year-header {
      color: var(--font-color);
      font-size: 14px;
      margin: 0.766rem;
    }
    &__navigation--previous {
      border-right-color: var(--primary-color);
      outline: none;
      top: 10px;
      &:hover {
        opacity: 0.7;
      }
    }
    &__navigation--next {
      border-left-color: var(--primary-color);
      top: 10px;
      outline: none;
      &:hover {
        opacity: 0.7;
      }
    }
    &__navigation--years-previous,
    &__year-read-view--down-arrow {
      border-color: var(--primary-color) !important;
      &:hover {
        opacity: .7;
      }
    }
    &__navigation--years-upcoming {
      border-color: var(--primary-color) !important;
      &:hover {
        border-color: var(--primary-color);
        opacity: .7;
      }
    }
    &__year-option--selected {
      color: var(--primary-color);
      &:hover {
        color: var(--primary-color);
        opacity: .7;
      }
    }
    &__year-dropdown {
      background-color: var(--block-background-color);
    }
    &__year-option {
      &:hover {
        background-color: var(--primary-color);
      }
      &:has(.react-datepicker__navigation--years-previous),
      &:has(.react-datepicker__navigation--years-upcoming) {
        background-color: var(--primary-color) !important;
        &:hover {
          opacity: .7;
        }
      }
    }
    &__day,
    &__month-text,
    &__year-text {
      margin: 0.766rem;
      color: var(--font-color);
      &:hover {
        background-color: var(--navbar-item-background-hover);
      }
    }
    &__day--disabled,
    &__year-text--disabled,
    &__month--disabled {
      opacity: 0.4;
      cursor: not-allowed;
      pointer-events: all;
    }
    &__day--selected,
    &__day--keyboard-selected,
    &__day--in-selecting-range,
    &__day--in-range,
    &__month--selected,
    &__month--in-range,
    &__month-text--keyboard-selected,
    &__year-text--selected,
    &__year-text--keyboard-selected {
      background-color: var(--primary-color);
      color: var(--white);
    }
    &__day--today {
      border-radius: 0.3rem;
      background-color: var(--primary-color);
      color: var(--white);
    }
    &__day-name {
      border-radius: 0.3rem;
      opacity: 0.4;
    }
    &__triangle {
      border-top-color: var(--navbar-item-background-hover) !important;
      border-bottom-color: var(--navbar-item-background-hover) !important;
    }
  }
  .react-datepicker-time {
    &__header {
      color: var(--font-color);
    }
  }
  .react-datepicker__time-list-item {
    height: 20px !important;
    padding: 5px !important;
    &.react-datepicker__time-list-item--selected {
      background-color: var(--primary-color) !important;
    }
  }
}

.react-datepicker__view-calendar-icon input {
  padding: 5px 30px 5px 10px;
}
.react-datepicker__input-container .react-datepicker__calendar-icon {
  top: 9px;
  right: 6px;
  font-size: 1.7rem;
  padding: 0;
  z-index: 1;
  color: var(--bulma-input-icon-color);
}
